/* CSS of Sixth Section Start Here */
.sixthSectionOfBlogsAndArticlesPageStartHere {
    padding: 3rem 0;
}

.readOurPopularBlogsHeading {
    padding-bottom: 2rem;
}

.readOurPopularBlogsHeading p {
    font-weight: 700;
    font-size: 21px;
    line-height: 41px;
    color: #c08b00;
    text-align: center;
}

.readOurPopularBlogsHeading h6 {
    font-weight: 700;
    font-size: 33px;
    line-height: 71px;
    color: #000000;
    margin-top: -30px;
    text-align: center;
}

.cardImgHeadingParaAndButtonBlogInnerDiv {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    height: 100%;
}

.cardImgHeadingParaAndButtonBlogInnerDiv img {
    width: 100%;
}

.blogsCardParaAndHeading {
    padding: 1rem;
}

.cardImgHeadingParaAndButtonBlogInnerDiv h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;
    color: #000000;
}

.cardImgHeadingParaAndButtonBlogInnerDiv .readOurBlogsPara {
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #000000;
}

.cardImgHeadingParaAndButtonBlogInnerDiv .buttonPara {
    font-weight: 700;
    font-size: 19px;
    line-height: 41px;
}

.cardImgHeadingParaAndButtonBlogInnerDiv a {
    text-decoration: none;
    color: #000000;
}

.cardImgHeadingParaAndButtonBlogInnerDiv a:hover {
    color: #000000;
    border-bottom: 2px solid #000000;
}

/* Responsive Page's CSS of Sixth Section Start Here */
@media only screen and (max-width: 991px) {
    .container.sixthSectionOfBlogsAndArticlesFirstInnerDiv {
        max-width: 100%;
    }
    .sixthSectionOfBlogsAndArticlesPageStartHere {
        padding: 3rem 2rem;
    }
}
@media only screen and (max-width: 425px) {
    .sixthSectionOfBlogsAndArticlesPageStartHere {
        padding: 3rem 0.5rem;
    }
}