.nav-toggle-button {
  display: none;
}

.nav-toggle-button span i {
  font-size: 25px;
  cursor: pointer;
  font-weight: 800;
  line-height: 43px;
}

.main-nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
  width: 100%;
  padding: 0.3rem 1rem 0.3rem 3.5rem;
}

.nav-image-div {
  width: 141px;
}

.nav-image-div img {
  width: 100%;
}

.nav-menu > ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-menu > ul > li {
  padding: 0.9rem 2rem;
  position: relative;
}

.nav-menu > ul > li > a,
.sub-menu li a {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  line-height: 39px;
}

.nav-menu > ul > li > a {
  font-weight: 600;
}

.nav-menu ul li a {
  text-decoration: none;
}

.nav-menu ul li a button {
  background: transparent;
  border: 3px solid #000000;
  padding: 0.1rem 2rem;
  color: #000000;
  font-weight: 600;
  border-radius: 328px;
}

.nav-un-ordered-list .active {
  border-bottom: 3px solid #000000;
}

.nav-menu > ul > .contactUs .active {
  border-bottom: none;
}

.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 253px;
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(83, 83, 83, 0.25);
  border-radius: 13px;
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  list-style: none;
}

.nav-menu > ul > li:hover > ul {
  height: 175px;
}

.sub-menu li {
  padding: 0.5rem 0;
  width: 100%;
}

.sub-menu li a {
  font-weight: 500;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1110px) {
  .nav-menu > ul > li a {
    font-size: 19px;
  }
  .nav-menu > ul > li {
    padding: 0.9rem 1.5rem;
  }
  .nav-image-div {
    width: 121px;
  }
}
@media only screen and (max-width: 1100px) {
  .nav-menu > ul > li a {
    font-size: 19px;
  }
  .nav-image-div {
    width: 121px;
  }
}
@media only screen and (max-width: 991px) {
  .nav-menu > ul > li a {
    font-size: 17px;
  }
  .nav-menu > ul > li {
    padding: 0.9rem 1rem;
  }
  .nav-menu ul li a button {
    padding: 0.1rem 1rem;
  }
}
@media only screen and (max-width: 850px) {
  .main-nav-menu {
    padding: 0.3rem 1rem 0.3rem 2.5rem;
  }
  .nav-menu > ul > li {
    padding: 0.9rem 0.7rem;
  }
}
@media only screen and (max-width: 767px) {
  .main-nav-menu {
    padding: 0.8rem 1rem 0.8rem 1.5rem;
  }
  .main-nav-menu {
    display: inline-block;
  }
  .nav-menu > ul {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0.5rem;
  }
  .nav-menu > ul > li {
    padding: 0.5rem 0.3rem;
    position: unset;
  }
  .nav-menu > ul > .contactUs {
    margin-top: 0rem;
    width: auto;
  }
  .nav-toggle-button {
    display: block;
    position: absolute;
    right: 3rem;
  }
  .nav-un-ordered-list {
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
  }
  .show-nav-un-ordered-list {
    height: 27.5rem;
  }
  .nav-menu ul li a button {
    padding: 0.1rem 2rem;
  }
  .sub-menu {
    position: unset;
    left: 0;
    top: 100%;
    width: 100%;
    background: none;
    height: auto;
    margin-bottom: -17px;
    padding-left: 1.5rem;
    box-shadow: none;
  }
}
@media only screen and (max-width: 575px) {
  .nav-image-div {
    width: 111px;
  }
}
@media only screen and (max-width: 425px) {
  .nav-toggle-button {
    right: 1.5rem;
  }
}
