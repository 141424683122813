.firstSectionOfCompanyPageStartHere {
  padding: 5% 0 3% 0;
}

.companyPageHeadingDiv p {
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  color: #c08b00;
}

.companyPageHeadingDiv h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 49px;
  color: #000000;
  margin-bottom: 39px;
  margin-top: -20px;
}

.companyPageMainContentDiv h3 {
  font-weight: 700;
  font-size: 23px;
  line-height: 41px;
  color: #000000;
}

.companyPageMainContentDiv p {
  font-weight: 500;
  font-size: 19px;
  line-height: 39px;
  color: #000000;
  text-align: justify;
  margin-bottom: 2.5rem;
}

.secondSectionOfCompanyPageStartHere {
  padding: 0 0 5% 0;
}

.companyPageMembersHeadingDiv p,
.companyPageTeamMembersHeadingDiv p {
  font-weight: 600;
  font-size: 27px;
  line-height: 45px;
  color: #4a4a4a;
  text-align: center;
}

.foundingMembersImgAndParaDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.foundingMembersImgAndParaDiv img {
  transform: scale(0.8) translateY(-1rem);
}

.foundingMembersImgAndParaDiv h6 {
  font-weight: 600;
  font-size: 22px;
  color: #000000;
}

.teamMembersImgAndParaDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.teamMembersImgAndParaDiv img {
  width: 253px;
  padding: 1rem 0;
}

.temporaryImageDiv img {
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(255, 231, 18, 0.4);
  border-radius: 151px 149px 0px 0px;
  overflow: hidden;
  width: 275px;
  height: 0;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.teamMembersImgAndParaDiv:hover .overlay {
  top: 0;
  height: 100%;
}

.text {
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.text h5 {
  color: #000000;
  font-size: 21px;
  font-weight: 700;
}

.text p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.thirdSectionOfCompanyPageStartHere,
.fifthSectionOfCompanyPageStartHere {
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
  .overlay {
    width: 275px;
  }
}
@media only screen and (max-width: 991px) {
  .overlay {
    width: 233px;
  }
}
@media only screen and (max-width: 767px) {
  .overlay {
    width: 169px;
  }
  .text h5 {
    font-size: 18px;
  }
  .text p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .firstSectionOfCompanyPageStartHere {
    padding: 5% 3% 3% 3%;
  }
  .overlay {
    width: 271px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .text h5 {
    font-size: 20px;
  }
  .text p {
    font-size: 17px;
  }
}
