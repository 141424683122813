
.our-creator-individual-page-section-start-here {
    padding: 3rem 2.5rem 3rem 2.5rem;
}

.our-creator-individual-page-heading-div {
    padding: 0rem 0 1rem 0;
}

.our-creator-individual-page-heading-div p {
    color: #c08b00;
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    margin-bottom: 0rem;
}

.our-creator-individual-page-heading-div h6 {
    color: #000;
    font-size: 33px;
    font-weight: 700;
    line-height: 71px;
    margin-bottom: 0px;
    margin-top: -15px;
    text-align: center;
}

.col-md-5.our-creator-about-me-div {
    padding: 0 2rem 0 0;
}

.our-creator-about-me-div, .our-creator-blogs-and-industries-div {
    height: 100%;
}

.our-creator-about-me-first-inner-div {
    background: rgba(217, 217, 217, 0.4);
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-div img {
    width: 175px;
    height: auto;
}

.creator-name-div h3 {
    padding-top: 1rem;
    text-align: center;
    color: #000000;
    font-weight: 700;
}

/* .creator-desig-and-social-icon-div {

} */

.creator-desig-and-social-icon-div p {
    text-align: center;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0rem;
}

.creator-desig-and-social-icon-div {
    padding-bottom: 1.5rem;
    position: relative;
    width: 100%;
}

.creator-desig-and-social-icon-div img {
    width: 37px;
    height: auto;
    position: absolute;
    right: 0rem;
    top: -27%;
    cursor: pointer;
}

.creator-para-div p {
    text-align: justify;
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
}

.our-creator-blogs-heading-div {
    padding-bottom: 0.5rem;
}

.our-creator-blogs-heading-div h2 {
    font-weight: 500;
    font-size: 33px;
}

.our-creator-blogs-icon-heading-and-para-div {
    margin: 1.5rem 0;
}

.our-creator-blogs-icon-heading-and-para-first-inner-div {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 9px 17px 0px rgba(109, 109, 109, 0.23);
    padding: 1.25rem;
    height: 100%;
}

.our-creator-blogs-icon-heading-and-para-first-inner-div img {
    width: 51px;
    height: auto;
}

.our-creator-blogs-icon-heading-and-para-first-inner-div h4 {
    font-weight: 700;
    color: #000000;
    font-size: 19px;
    padding-top: 1rem;
    line-height: 31px;
}

.our-creator-blogs-icon-heading-and-para-first-inner-div p {
    font-weight: 500;
    color: #000000;
    font-size: 17px;
    line-height: 29px;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 1199px) {
    .col-md-5.our-creator-about-me-div {
        padding: 0 1.5rem 0 0;
    }
}
@media only screen and (max-width: 1000px) {
    .col-md-5.our-creator-about-me-div {
        padding: 0 0.5rem 0 0;
    }
}
@media only screen and (max-width: 991px) {
    .our-creator-individual-page-section-start-here {
        padding: 3rem 1.5rem 3rem 1.5rem;
    }
    .our-creator-about-me-first-inner-div {
        padding: 3rem;
    }
    .our-creator-blogs-and-industries-div {
        padding-top: 2rem;
    }
}
@media only screen and (max-width: 575px) {
    .our-creator-individual-page-section-start-here {
        padding: 3rem 0.5rem 3rem 0.5rem;
    }
    .our-creator-about-me-first-inner-div {
        padding: 1.5rem;
    }
}