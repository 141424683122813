
/* CSS of First Section Start Here */
.firstSectionOfWebsiteContentWritingPageStartHere {
  padding: 2.5rem 0;
  background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
}

.firstSectionOfWebsiteContentWritingPageStartHere h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 59px;
  color: #000000;
  text-align: justify;
}

.firstSectionOfWebsiteContentWritingPageStartHere p {
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
  text-align: justify;
}

.websiteContentWritingHeadingAndParaDiv {
  padding-top: 0.5rem;
}

/* Responsive Page's CSS of First Section Start Here */
@media only screen and (max-width: 1200px) {
  .websiteContentWritingHeadingAndParaDiv {
    padding-top: 0rem;
  }
  .firstSectionOfWebsiteContentWritingPageStartHere h1 {
    font-size: 40px;
}
}
@media only screen and (max-width: 991px) {
  .firstSectionOfWebsiteContentWritingPageStartHere h1 {
    text-align: justify;
}
}
@media only screen and (max-width: 767px) {
  .firstSectionOfWebsiteContentWritingPageStartHere {
    padding: 2.5rem 4.5rem 1.5rem 4.5rem;
  }
  .firstSectionOfWebsiteContentWritingPageStartHere h1,
  .firstSectionOfWebsiteContentWritingPageStartHere p {
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .firstSectionOfWebsiteContentWritingPageStartHere {
    padding: 0.5rem 1rem 1.5rem 1rem;
}
}
@media only screen and (max-width: 375px) {
  .firstSectionOfWebsiteContentWritingPageStartHere h1 {
    font-size: 31px;
  }
}

/* CSS of Second Section Start Here */
.secondPageOfWebsiteContentWritingPageStartHere {
  padding-bottom: 2rem;
}

.websiteContentWritingSecondSectionHeadingAndParaDiv {
  padding-top: 3rem;
}

.websiteContentWritingSecondSectionHeadingAndParaDiv
  .secondSectionFirstHeadingPara,
.WebsiteContentWritingThirdSectionHeading p, .first-question-heading-div p, .second-question-para-div p, .third-question-para-div p {
  font-weight: 700;
  font-size: 21px;
  line-height: 41px;
  color: #c08b00;
}

.websiteContentWritingSecondSectionHeadingAndParaDiv h6,
.WebsiteContentWritingThirdSectionHeading h6, .first-question-heading-div h6, .second-question-para-div h6, .third-question-para-div h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  margin-top: -30px;
}

.first-question-heading-div p, .first-question-heading-div h6, .second-question-heading-div p, .second-question-para-div h6, .third-question-para-div p, .third-question-para-div h6 {
  text-align: center;
}

.first-question-website-content-section-start {
  padding: 1rem 0 2rem 0;
}

.second-question-website-content-section-start, .third-question-website-content-section-start {
  padding-bottom: 2rem;
}

.websiteContentWritingSecondSectionHeadingAndParaDiv .servicesDescPara, .first-question-para-div p, .second-question-para-div p, .third-question-para-div p {
  font-weight: 500;
  font-size: 19px;
  line-height: 35px;
  text-align: justify;
  color: #393939;
}

/* Responsive Page's CSS of Second Section Start Here */
@media only screen and (max-width: 1199px) {
  .websiteContentWritingSecondSectionHeadingAndParaDiv {
    padding-top: 1.5rem;
  }
}
@media only screen and (max-width: 991px) {
  .websiteContentWritingSecondSectionHeadingAndParaDiv h6 {
    line-height: 49px;
    margin-top: -19px;
  }
  .secondPageOfWebsiteContentWritingPageStartHere {
    padding: 2rem 2rem 4rem 2rem;
}
  .container.secondPageOfWebsiteContentWritingPageInnerDiv {
    max-width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .websiteContentWritingSecondSectionImgDiv img {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .websiteContentWritingSecondSectionHeadingAndParaDiv h6,
  .websiteContentWritingSecondSectionHeadingAndParaDiv
    .secondSectionFirstHeadingPara {
    text-align: center;
  }
  .secondPageOfWebsiteContentWritingPageStartHere {
    padding: 0 1rem 2.5rem 1rem;
  }
}
@media only screen and (max-width: 500px) {
  .secondPageOfWebsiteContentWritingPageStartHere {
    padding: 0 1rem 2.5rem 1rem;
  }
}

/* CSS of Third Section Start Here */
.thirdSectionOfWebsiteContentWritingPageStartHere {
  margin-top: -50px;
}

.WebsiteContentWritingThirdSectionHeading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.WebsiteContentWritingThirdSectionHeading h6 {
  padding: 0 15rem;
  text-align: center;
  line-height: 55px;
}

.thirdSectionOfWebsiteContentWritingPageStartHere {
  background-image: url("../../../Image/Categories_Page/Select_Services.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 600px;
  width: 100%;
  padding: 5% 2%;
}

.thirdSectionCardMainDiv {
  margin: 1.5rem 0;
}

.thirdSectionWritingCardDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1rem;
  height: 100%;
}

.thirdSectionWritingCardDiv img {
  width: 55px;
  margin-bottom: 15px;
}

.thirdSectionWritingCardDiv h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.thirdSectionWritingCardDiv p {
  font-weight: 400;
  font-size: 18px;
  line-height: 39px;
  text-align: justify;
  color: #797979;
  padding: 0 2rem;
}

/* Responsive Page's CSS of Third Section Start Here */
@media only screen and (max-width: 991px) {
  .WebsiteContentWritingThirdSectionHeading h6 {
    padding: 0 0rem;
    line-height: 44px;
    margin-top: -20px;
  }
}
@media only screen and (max-width: 767px) {
  .thirdSectionWritingCardDiv {
    padding: 1rem 1rem;
  }
  .thirdSectionWritingCardDiv p {
    padding: 0 0rem;
  }
  .WebsiteContentWritingThirdSectionHeading h6 {
    margin-bottom: 3rem;
  }
}
@media only screen and (max-width: 575px) {
  .thirdSectionWritingCardDiv {
    padding: 1rem 1rem;
  }
}

/* CSS of Fifth Section Start Here */
.fifthSectionOfWebsiteContentWritingPageStartHere {
  background: #ffe589;
  padding: 5% 0;
  margin-top: 3rem;
}

.subscribeFormDivUnderWebsiteContentWritingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subscribeFormDivUnderWebsiteContentWritingPage h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 71px;
  color: #000000;
}

/* Responsive Page's CSS of Fifth Section Start Here */
@media only screen and (max-width: 767px) {
  .subscribeFormDivUnderWebsiteContentWritingPage h2 {
    font-size: 27px;
    line-height: 61px;
  }
}
@media only screen and (max-width: 500px) {
  .fifthSectionOfWebsiteContentWritingPageStartHere {
    padding: 8% 0;
  }
  .subscribeFormDivUnderWebsiteContentWritingPage h2 {
    line-height: 47px;
    text-align: center;
  }
}
