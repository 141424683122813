
.moveToTopDiv img {
    width: 65px;
    position: fixed;
    right: 3rem;
    bottom: 2.8rem;
    z-index: 999;
    cursor: pointer;
}

/* Responsive Component Start Here */
@media only screen and (max-width: 991px) {
    .moveToTopDiv img {
        right: 2rem;
    }
}
@media only screen and (max-width: 425px) {
    .moveToTopDiv img {
        right: 1.5rem;
        width: 55px;
    }
}