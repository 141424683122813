.categoriesServicesFormsInnerDiv input, .categoriesServicesFormsTextareaDiv textarea {
    background: #FFFFFF;
    border: 1px solid #606060;
    border-radius: 9px;
    padding: 0.9rem 4rem;
}

.categoriesServicesFormsInnerDiv input::placeholder, .categoriesServicesFormsTextareaDiv textarea::placeholder {
    font-weight: 500;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 0.025em;
    color: #797979;
}

.categoriesServicesFormsTextareaDiv textarea::placeholder {
    line-height: 25px;
}

.categoriesServicesFormsInnerDiv img, .categoriesServicesFormsTextareaDiv img {
    position: absolute;
    width: 31px;
    transform: translate(1.1rem, 0.7rem);
}

.categoriesServicesFormsButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoriesServicesFormsButtonDiv button {
    padding: 0.5rem 4rem;
    background: #000000;
    border-radius: 72px;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    border: none;
    outline: none;
}

/* Responsive Page Start Here */
