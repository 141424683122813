
.categoryBlogsSectionStartHere {
    padding: 0 3rem;
}

.categoryBlogsHeadingDiv h2 {
    font-weight: 500;
    font-size: 33px;
    line-height: 45px;
    letter-spacing: 0.025em;
    color: #000000;
    padding: 3rem 0 1rem 2rem;
}

.categoryBlogsDiv {
    padding-bottom: 1.5rem;
}

.categoryBlogRightCardInnerDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.categoryBlogCardHeadingAndPara {
    padding-top: 1rem;
}

.cartegoryBlogImgDiv img {
    height: 185px;
    width: 501px;
}

.categoryBlogCardHeadingAndPara .categoryBlogCardAuthorNameAndDate {
    font-weight: 500;
    font-size: 15px;
    line-height: 11px;
    text-align: justify;
    color: #555555;
}

.categoryBlogCardHeadingAndPara h3 {
    font-weight: 700;
    font-size: 23px;
    line-height: 29px;
    text-align: justify;
    color: #000000;
    margin-top: -0.7rem;
}

.categoryBlogCardHeadingAndPara .categoryBlogCardPara {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: justify;
    color: #262626;
}

.categoryBlogCardHeadingAndPara a {
    text-decoration: none;
    color: #000000;
}

.categoryBlogCardHeadingAndPara a:hover {
    color: #000000;
    border-bottom: 2px solid #000000;
}

.categoryBlogCardHeadingAndPara .categoryBlogReadMoreBtn {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: justify;
    color: #000000;
}

hr {
    margin: 1rem 0 !important;
    border-top: 2px solid #000000 !important;
    opacity: 1 !important;
}

/* hr:last-of-type {
    display: none;
} */

/* .categoryBlogRightCardForBelowLine {
    /* border-bottom: 2px solid #000000; */
    /* padding: 1.5rem 0; */
    /* margin-bottom: 1.5rem; */


/* Responsive Page Start Here */
@media only screen and (max-width: 991px) {
    .categoryBlogsHeadingDiv h2 {
        padding: 3rem 0 1rem 0rem;
    }
    .categoryBlogsDiv {
        padding-bottom: 1rem;
    }
}
@media only screen and (max-width: 767px) {
    .cartegoryBlogImgDiv img {
        height: 208px;
        width: 701px;
    }
}
@media only screen and (max-width: 575px) {
    .ms-4 {
        margin-left: 0rem!important;
    }
    .categoryBlogRightCardInnerDiv {
        flex-direction: column;
    }
}