.landingPageMainDiv {
  overflow-x: hidden;
}

/* First Section Start Here */
.firstSectionOfLandingPageStartHere {
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
  width: 100%;
  height: auto;
  border-bottom-right-radius: 50% 85px;
  border-bottom-left-radius: 50% 85px;
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;
}

.bannerSectionHeadingParaAndButton {
  padding: 11% 0 0 0;
}

.bannerSectionHeadingParaAndButton h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 55px;
  color: #000000;
}

.bannerSectionHeadingParaAndButton p {
  font-weight: 300;
  font-size: 22px;
  line-height: 39px;
  color: #292929;
  margin-bottom: 35px;
}

.bannerSectionHeadingParaAndButton a {
  text-decoration: none;
}

.bannerSectionHeadingParaAndButton button {
  background: #000000;
  border-radius: 52px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  padding: 0.7rem 3rem;
  font-family: var(--font-family-4);
  border: none;
  outline: none;
}

.bannerImgSection img {
  position: relative;
  width: 650px;
  transform: translate(-5rem, -1rem);
}

/* Responsive First Section Start Here */
@media only screen and (max-width: 1199px) {
  .bannerSectionHeadingParaAndButton {
    padding: 4% 0 0 0;
  }
  .bannerImgSection img {
    transform: translate(-5rem, -3rem);
  }
}
@media only screen and (max-width: 1100px) {
  .bannerImgSection img {
    width: 600px;
    transform: translate(-7rem, -3rem);
  }
}
@media only screen and (max-width: 991px) {
  .container.firstSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
  .firstSectionOfLandingPageStartHere {
    padding: 0 2rem 4rem 2rem;
  }
  .bannerImgSection img {
    width: 550px;
    transform: translate(-5.5rem, -2rem);
  }
}
@media only screen and (max-width: 950px) {
  .bannerImgSection img {
    width: 500px;
    transform: translate(-3.5rem, -2rem);
  }
}
@media only screen and (max-width: 925px) {
  .bannerSectionHeadingParaAndButton {
    padding: 4% 0 4% 0;
  }
}
@media only screen and (max-width: 900px) {
  .bannerImgSection img {
    width: 450px;
    transform: translate(-3.5rem, 0rem);
  }
}
@media only screen and (max-width: 768px) {
  .bannerImgSection img {
    transform: translate(-5.5rem, 0rem);
  }
}
@media only screen and (max-width: 767px) {
  .bannerSectionHeadingParaAndButton h1 {
    font-size: 37px;
    text-align: center;
  }
  .bannerSectionHeadingParaAndButton p {
    text-align: center;
  }
  .bannerSectionHeadingParaAndButton button {
    display: flex;
    margin: auto;
  }
  .bannerImgSection img {
    transform: translate(0rem, 0rem);
    display: flex;
    margin: auto;
    width: 500px;
  }
  .bannerSectionHeadingParaAndButton {
    padding: 4% 0 1% 0;
  }
}
@media only screen and (max-width: 575px) {
  .firstSectionOfLandingPageStartHere {
    border-bottom-right-radius: 50% 54px;
    border-bottom-left-radius: 50% 54px;
  }
  .bannerImgSection img {
    transform: translate(-23px, 0px);
  }
}
@media only screen and (max-width: 524px) {
  .bannerImgSection img {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .firstSectionOfLandingPageStartHere {
    padding: 0 1rem 4rem 1rem;
  }
}

/* Questions CSS */
.first-question-heading-div p, .second-question-heading-div p, .third-question-heading-div p, .fourth-question-heading-div p {
  font-weight: 700;
  font-size: 21px;
  line-height: 41px;
  color: #c08b00;
}

.first-question-heading-div h6, .second-question-heading-div h6, .third-question-heading-div h6, .fourth-question-heading-div h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 51px;
  color: #000000;
  margin-top: -30px;
}

.first-question-section-start {
  padding-top: 3rem;
  padding-bottom: 0rem;
}

.second-question-section-start {
  padding-bottom: 0rem;
  padding-top: 2rem;
}

.third-question-section-start {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.fourth-question-section-start {
  padding-top: 2rem;
}

.first-question-heading-div h6, .second-question-heading-div h6, .third-question-heading-div h6, .fourth-question-heading-div h6, .first-question-heading-div p, .second-question-heading-div p, .third-question-heading-div p, .fourth-question-heading-div p {
  text-align: center;
}

.first-question-para-div p, .second-question-para-div p, .third-question-para-div p, .fourth-question-para-div p, .first-question-para-div ul li, .second-question-para-div ul li, .third-question-para-div ul li {
  font-weight: 500;
  font-size: 19px;
  line-height: 35px;
  text-align: justify;
  color: #393939;
}

.first-question-para-div ul li, .second-question-para-div ul li, .third-question-para-div ul li {
  margin: 1rem 0;
}

/* Second Section Start Here */
.secondSectionOfLandingPageStartHere {
  padding: 4% 0 0 0;
}

.secondSectionContentAndButtonDiv {
  padding-top: 1%;
}

.secondSectionContentAndButtonDiv .sectionSectionFirstHeading {
  font-weight: 700;
  font-size: 21px;
  line-height: 43px;
  color: #eaa800;
  margin-bottom: 0;
}

.secondSectionContentAndButtonDiv h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 49px;
  color: #000000;
  margin-bottom: 39px;
}

.secondSectionContentAndButtonDiv .secondSectionPara, .secondSectionContentAndButtonDiv p {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
  padding-right: 9%;
}

.secondSectionContentAndButtonDiv p {
  padding-right: 0;
}

.secondSectionContentAndButtonDiv .iconAndIdeas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  position: relative;
  /* left: -2rem; */
}

.secondSectionContentAndButtonDiv .iconAndIdeas p {
  font-weight: 700;
  font-size: 19px;
  line-height: 41px;
  text-align: justify;
  color: #000000;
  /* margin: 0 1.5rem 1rem 1.5rem; */
}

.secondSectionContentAndButtonDiv .iconAndIdeas img {
  width: 25px;
}

.secondSectionContentAndButtonDiv a {
  text-decoration: none;
}

.secondSectionContentAndButtonDiv button {
  background: #000000;
  border-radius: 56px;
  color: #ffffff;
  font-weight: 700;
  font-family: var(--font-family-4);
  font-size: 18px;
  line-height: 39px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  margin: 0 auto;
  display: flex;
}

/* Responsive Second Section Start Here */
@media only screen and (max-width: 1199px) {
  .secondSectionImageDiv img {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 991px) {
  .secondSectionImageDiv img {
    padding-top: 0rem;
    width: 550px;
    display: flex;
    margin: auto;
  }
  .secondSectionOfLandingPageStartHere {
    padding: 4% 3rem 0 3rem;
  }
  .secondSectionContentAndButtonDiv .sectionSectionFirstHeading,
  .secondSectionContentAndButtonDiv h6 {
    text-align: center;
  }
  .secondSectionContentAndButtonDiv h6 {
    margin-bottom: 15px;
  }
  .secondSectionContentAndButtonDiv .secondSectionPara {
    padding-right: 0%;
  }
}
@media only screen and (max-width: 575px) {
  .secondSectionOfLandingPageStartHere {
    padding: 4% 3rem 3rem 3rem;
  }
}
@media only screen and (max-width: 425px) {
  .secondSectionOfLandingPageStartHere {
    padding: 4% 1rem 0 1rem;
  }
}

/* Third Section New Coding Start Here */
.thirdSectionOfLandingPageStartHere {
  padding: 3% 0% 3% 0%;
}

.bestContentsGetFromUsFirstInnerDiv {
  background: #ffe589;
  box-shadow: 0px 4px 26px rgba(84, 73, 73, 0.25);
  border-radius: 30px;
  height: 401px;
  padding: 2rem 3rem;
}

.bestContentsGetFromUsFirstInnerDiv h6 {
  font-weight: 700;
  font-size: 23px;
  line-height: 39px;
  color: #000000;
}

.bestContentsGetFromUsFirstInnerDiv p {
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  color: #000000;
  /* padding: 3% 31% 5% 0; */
  text-align: justify;
}

.bestContentsGetFromUsFirstInnerDiv h3 {
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: #000000;
  margin-bottom: 7%;
}

.bestContentsGetFromUsFirstInnerDiv button {
  background: #000000;
  border-radius: 139px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 0.5rem 2rem;
  margin-bottom: 2%;
  border: none;
  outline: none;
}

/* .bestContentsGetFromUsFirstInnerDiv .websiteContentBtn {
  margin-top: 2rem;
} */

.bestContentsGetFromUsFirstInnerDiv .blogArticlesBtn,
.bestContentsGetFromUsFirstInnerDiv .productDescriptionBtn {
  margin-top: 2rem;
}

/* Respponsive CSS Coding of Third Section Start Here */
@media only screen and (max-width: 1199px) {
  .container.thirdSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .bestContentsGetFromUsFirstInnerDiv {
    height: 451px;
  }
  .bestContentsGetFromUsFirstInnerDiv .websiteContentBtn {
    margin-top: 2rem;
  }
  .bestContentsGetFromUsFirstInnerDiv .blogArticlesBtn {
    margin-top: 5rem;
  }
  .bestContentsGetFromUsFirstInnerDiv .productDescriptionBtn {
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 991px) {
  .bestContentsGetFromUsDiv {
    padding: 0 7rem;
  }
  .bestContentsGetFromUsFirstInnerDiv h3 {
    margin-bottom: 4%;
  }
  .bestContentsGetFromUsFirstInnerDiv .websiteContentBtn {
    margin-top: -1rem;
  }
  .bestContentsGetFromUsFirstInnerDiv .blogArticlesBtn {
    margin-top: 1.5rem;
  }
  .bestContentsGetFromUsFirstInnerDiv .productDescriptionBtn {
    margin-top: 1.5rem;
  }
  .bestContentsGetFromUsFirstInnerDiv {
    height: 301px;
  }
}
@media only screen and (max-width: 767px) {
  .bestContentsGetFromUsFirstInnerDiv {
    height: auto;
  }
  .bestContentsGetFromUsFirstInnerDiv .websiteContentBtn {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .bestContentsGetFromUsDiv {
    padding: 0 3rem;
  }
}
@media only screen and (max-width: 425px) {
  .bestContentsGetFromUsDiv {
    padding: 0 1rem;
  }
}

/* Third Section New Coding End Here */

/* Third Section Start Here */
/* .thirdSectionOfLandingPageStartHere {
  padding: 5% 0% 3% 0%;
}

.bestContentsGetFromUsLeftDiv .bestContentsGetFromUsLeftInnerDiv {
  padding: 6% 5% 6% 5%;
}

.bestContentsGetFromUsLeftDiv::after {
  content: "";
  background-image: url("../../Image/Landing_Page/Content/website.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 200px;
  height: 200px;
  transform: translate(18rem, -7rem) scale(1.2);
}

.bestContentsGetFromUsRightFirstInnerDiv::after {
  content: "";
  background-image: url("../../Image/Landing_Page/Content/blog.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 175px;
  height: 175px;
  transform: translate(11rem, -5.5rem) scale(0.9);
}

.bestContentsGetFromUsRightSecondInnerDiv::after {
  content: "";
  background-image: url("../../Image/Landing_Page/Content/product.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 175px;
  height: 175px;
  transform: translate(11rem, -5.5rem) scale(0.9);
}

.bestContentsGetFromUsRightDiv .bestContentsGetFromUsRightFirstInnerDiv,
.bestContentsGetFromUsRightDiv .bestContentsGetFromUsRightSecondInnerDiv {
  padding: 5%;
}

.bestContentsGetFromUsLeftDiv,
.bestContentsGetFromUsRightDiv .bestContentsGetFromUsRightFirstInnerDiv,
.bestContentsGetFromUsRightDiv .bestContentsGetFromUsRightSecondInnerDiv {
  background: #ffe589;
  box-shadow: 0px 4px 26px rgba(84, 73, 73, 0.25);
  border-radius: 30px;
  height: auto;
}

.bestContentsGetFromUsLeftInnerDiv h6,
.bestContentsGetFromUsRightFirstInnerDiv h6,
.bestContentsGetFromUsRightSecondInnerDiv h6 {
  font-weight: 700;
  font-size: 23px;
  line-height: 45px;
  color: #000000;
}

.bestContentsGetFromUsLeftInnerDiv p {
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  color: #000000;
  padding: 3% 31% 5% 0;
  text-align: justify;
}

.bestContentsGetFromUsLeftInnerDiv h3 {
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: #000000;
  margin-bottom: 7%;
}

.bestContentsGetFromUsLeftInnerDiv button {
  background: #000000;
  border-radius: 139px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 0.5rem 2rem;
  margin-bottom: 2%;
  border: none;
  outline: none;
}

.bestContentsGetFromUsRightFirstInnerDiv p,
.bestContentsGetFromUsRightSecondInnerDiv p {
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  color: #000000;
  padding: 0% 31% 0% 0;
  text-align: justify;
}

.bestContentsGetFromUsRightFirstInnerDiv button,
.bestContentsGetFromUsRightSecondInnerDiv button {
  background: #000000;
  border-radius: 139px;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
  padding: 0.3rem 1.5rem;
  border: none;
  outline: none;
} */

/* Responsive Third Section Start Here */
/* @media only screen and (max-width: 1199px) {
  .bestContentsGetFromUsRightFirstInnerDiv::after,
  .bestContentsGetFromUsRightSecondInnerDiv::after {
    transform: translate(7rem, -5.2rem) scale(0.8);
  }
  .bestContentsGetFromUsLeftDiv::after {
    transform: translate(14rem, -7rem) scale(1.1);
  }
}
@media only screen and (max-width: 991px) {
  .container.thirdSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
  .thirdSectionOfLandingPageStartHere {
    padding: 5% 5rem 3% 5rem;
  }
  .bestContentsGetFromUsLeftInnerDiv p {
    padding: 0% 29% 0% 0;
  }
  .bestContentsGetFromUsLeftInnerDiv button {
    margin-bottom: 0%;
  }
  .bestContentsGetFromUsLeftInnerDiv h3 {
    margin-bottom: 2%;
  }
  .bestContentsGetFromUsLeftDiv .bestContentsGetFromUsLeftInnerDiv {
    height: 10rem;
  }
  .bestContentsGetFromUsLeftDiv::after {
    transform: translate(0.4rem, -0.3rem) scale(1);
    float: right;
    position: relative;
  }
  .bestContentsGetFromUsRightFirstInnerDiv::after,
  .bestContentsGetFromUsRightSecondInnerDiv::after {
    transform: translate(3rem, -5.8rem) scale(0.9);
    position: relative;
    float: right;
  }
}
@media only screen and (max-width: 767px) {
  .bestContentsGetFromUsRightFirstInnerDiv::after,
  .bestContentsGetFromUsRightSecondInnerDiv::after {
    transform: translate(2.3rem, -6.5rem) scale(0.9);
  }
  .bestContentsGetFromUsLeftDiv::after {
    transform: translate(1.3rem, 0.5rem) scale(0.9);
  }
}
@media only screen and (max-width: 612px) {
  .bestContentsGetFromUsLeftDiv::after {
    transform: translate(1.8rem, 1rem) scale(0.8);
  }
  .bestContentsGetFromUsRightFirstInnerDiv::after,
  .bestContentsGetFromUsRightSecondInnerDiv::after {
    transform: translate(2.9rem, -5.8rem) scale(0.7);
  }
}
@media only screen and (max-width: 576px) {
  .thirdSectionOfLandingPageStartHere {
    padding: 5% 2rem 3% 2rem;
  }
  .bestContentsGetFromUsLeftInnerDiv p {
    padding: 0% 11% 0% 0;
}
}
@media only screen and (max-width: 528px) {
  .bestContentsGetFromUsLeftDiv::after {
    display: none;
  }
  .bestContentsGetFromUsRightFirstInnerDiv::after,
  .bestContentsGetFromUsRightSecondInnerDiv::after {
    display: none;
  }
  .bestContentsGetFromUsLeftDiv .bestContentsGetFromUsLeftInnerDiv {
    height: auto;
  }
  .bestContentsGetFromUsRightFirstInnerDiv, .bestContentsGetFromUsRightSecondInnerDiv {
    height: auto;
  }
  .bestContentsGetFromUsLeftInnerDiv button {
    margin-top: 1.5rem;
}
.bestContentsGetFromUsLeftInnerDiv h3 {
  margin-top: 1.5rem;
}
.bestContentsGetFromUsLeftInnerDiv p {
  padding: 0% 0% 0% 0;
}
.bestContentsGetFromUsRightFirstInnerDiv p, .bestContentsGetFromUsRightSecondInnerDiv p {
  padding: 0% 0% 0% 0;
}
}
@media only screen and (max-width: 425px) {
  .thirdSectionOfLandingPageStartHere {
    padding: 5% 0.5rem 3% 0.5rem;
}
} */

/* Fourth Section Start Here */
.fourthSectionOfLandingPageStartHere {
  padding: 4% 0 0 0;
}

.whyChooseUsHeading p,
.industriesWeCaterToHeading p,
.ourDeliveryProcessHeadingDiv p,
.bestContentsGetFromUsHeadingDiv p {
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  color: #c08b00;
  text-align: center;
}

.whyChooseUsHeading h6,
.industriesWeCaterToHeading h6,
.ourDeliveryProcessHeadingDiv h6,
.bestContentsGetFromUsHeadingDiv h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 41px;
}

.whyChooseUsCardDiv {
  margin-bottom: 51px;
}

.whyChooseUsCardFirstInnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 9px;
  background: #FFF;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  padding: 1rem 1rem;
}

.whyChooseUsCardDiv img {
  width: 95px;
  height: auto;
  filter: drop-shadow(0px 13px 8px rgba(19, 19, 19, 0.37));
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.whyChooseUsCardDiv img:hover {
  transform: scale(1.2) translateZ(0px);
}

.whyChooseUsCardDiv h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #000000;
}

.whyChooseUsCardDiv p {
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin-bottom: 0.5rem;
}

/* Responsive Fourth Section Start Here */
@media only screen and (max-width: 991px) {
  .container.fourthSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
  .fourthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
}
@media only screen and (max-width: 575px) {
  .fourthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
  .whyChooseUsCardDiv {
    margin-bottom: 37px;
  }
  .whyChooseUsHeading h6 {
    margin-bottom: 21px;
  }
}
@media only screen and (max-width: 528px) {
  .fourthSectionOfLandingPageStartHere {
    top: 0rem;
  }
}

/* Fifth Section Start Here */
.fifthSectionOfLandingPageStartHere {
  padding: 0 0 5% 0;
}

.ourDeliveryProcessImgHeadingAndParaDiv {
  padding: 0 2.5rem;
}

.ourDeliveryProcessImgHeadingAndParaDiv div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
  padding: 7% 7%;
  width: 100%;
  height: 325px;
}

.ourDeliveryProcessImgHeadingAndParaDiv img {
  width: 115px;
  margin-bottom: 35px;
}

.ourDeliveryProcessImgHeadingAndParaDiv h6 {
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: #000000;
}

.ourDeliveryProcessImgHeadingAndParaDiv p {
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  color: #585656;
  margin-top: 7px;
}

.ourDeliveryProcessImgHeadingAndParaFirstInnerDiv::before,
.ourDeliveryProcessImgHeadingAndParaThirdInnerDiv::before {
  content: url("../../Image/Landing_Page/Content_Delivery/Up_Arrow.png");
  position: absolute;
  transform: translate(9rem, -12rem) scale(0.8);
}

.ourDeliveryProcessImgHeadingAndParaSecondInnerDiv::after {
  content: url("../../Image/Landing_Page/Content_Delivery/Up_Arrow.png");
  position: absolute;
  transform: translate(8rem, 12rem) scale(0.8) rotateX(-180deg);
}

.ourDeliveryProcessImgHeadingAndParaFirstInnerDiv,
.ourDeliveryProcessImgHeadingAndParaSecondInnerDiv,
.ourDeliveryProcessImgHeadingAndParaThirdInnerDiv {
  transition: all 0.3s ease-in-out;
}

.ourDeliveryProcessImgHeadingAndParaFirstInnerDiv:hover,
.ourDeliveryProcessImgHeadingAndParaThirdInnerDiv:hover {
  background: #fff4cc;
  cursor: pointer;
}

.ourDeliveryProcessImgHeadingAndParaSecondInnerDiv,
.ourDeliveryProcessImgHeadingAndParaFourthInnerDiv {
  background: #fff4cc !important;
}

.ourDeliveryProcessImgHeadingAndParaSecondInnerDiv:hover,
.ourDeliveryProcessImgHeadingAndParaFourthInnerDiv:hover {
  background: #ffffff !important;
  cursor: pointer;
}

.askForMoneyForOurWork {
  padding: 2rem 0 0 0;
}

.askForMoneyPara p {
  text-align: center;
  font-size: 21px;
  font-weight: 600;
}

/* Responsive Fifth Section Start Here */
@media only screen and (max-width: 1199px) {
  .ourDeliveryProcessImgHeadingAndParaSecondInnerDiv::after {
    transform: translate(7rem, 12rem) scale(0.8) rotateX(-180deg);
  }
  .ourDeliveryProcessImgHeadingAndParaFirstInnerDiv::before,
  .ourDeliveryProcessImgHeadingAndParaThirdInnerDiv::before {
    transform: translate(7rem, -12rem) scale(0.8);
  }
}
@media only screen and (max-width: 991px) {
  .ourDeliveryProcessImgHeadingAndParaSecondInnerDiv::after {
    transform: translate(6rem, 12rem) scale(0.8) rotateX(-180deg);
  }
  .ourDeliveryProcessImgHeadingAndParaFirstInnerDiv::before,
  .ourDeliveryProcessImgHeadingAndParaThirdInnerDiv::before {
    transform: translate(6rem, -12rem) scale(0.8);
  }
  .container.fifthSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
  .askForMoneyPara p {
    position: relative;
    top: 0rem;
  }
  .fifthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
}
@media only screen and (max-width: 767px) {
  .ourDeliveryProcessImgHeadingAndParaDiv {
    margin: 2rem 0;
  }
  .ourDeliveryProcessImgHeadingAndParaFirstInnerDiv::before,
  .ourDeliveryProcessImgHeadingAndParaSecondInnerDiv::after,
  .ourDeliveryProcessImgHeadingAndParaThirdInnerDiv::before  {
    display: none;
  }
  .ourDeliveryProcessImgHeadingAndParaDiv div {
    width: 285px;
    height: 310px;
    margin: auto;
    padding: 4% 2%;
  }
  .ourDeliveryProcessHeadingDiv h6 {
    margin-bottom: 27px;
  }
  .askForMoneyForOurWork {
    padding: 0rem 0 0 0;
  }
  .askForMoneyPara p {
    top: 0rem;
  }
}
@media only screen and (max-width: 630px) {
  .ourDeliveryProcessImgHeadingAndParaDiv div {
    width: 228px;
}
}
@media only screen and (max-width: 575px) {
  .fifthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
  .askForMoneyPara p {
    top: 0rem;
  }
}
@media only screen and (max-width: 528px) {
  .fifthSectionOfLandingPageStartHere {
    top: 0rem;
  }
  .askForMoneyPara p {
    top: 0rem;
  }
}
@media only screen and (max-width: 510px) {
  .ourDeliveryProcessImgHeadingAndParaDiv div {
    width: 100%;
  }
}

/* Sixth Section Start Here */
.sixthSectionOfLandingPageStartHere {
  padding: 4% 0 0 0;
}

.industriesWeCaterToCardDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 65px;
}

.industriesWeCaterToCardDiv img {
  width: 100px;
  height: auto;
  margin-bottom: 11px;
  transition: all 0.3s ease-in-out;
}

.industriesWeCaterToCardDiv img:hover {
  transform: scale(1.2) translateZ(0px);
}

.industriesWeCaterToCardDiv h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
}

.industriesWeCaterToCardDiv h6 a {
  color: #000000;
  text-decoration: none;
}

.industriesWeCaterToCardDiv h6 a:hover {
  border-bottom: 2px solid #000000;
}

/* Responsive Sixth Section Start Here */
@media only screen and (max-width: 991px) {
  .sixthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
}
@media only screen and (max-width: 767px) {
  .industriesWeCaterToHeading h6 {
    margin-bottom: 23px;
  }
}
@media only screen and (max-width: 528px) {
  .sixthSectionOfLandingPageStartHere {
    top: 0rem;
  }
}

/* Seventh Section Start Here */
.seventhSectionOfLandingPageStartHere {
  padding: 5% 0 0 0;
}

.contentThatMakesYouContentHeadingAndPara .seventhSectionFirstHeading {
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  color: #c08b00;
}

.contentThatMakesYouContentHeadingAndPara .seventhSectionSecondHeading {
  font-weight: 700;
  font-size: 29px;
  line-height: 55px;
  color: #000000;
  margin-top: -20px;
  margin-bottom: 25px;
}

.contentThatMakesYouContentHeadingAndPara .seventhSectionFirstPara {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
  position: relative;
  z-index: 5;
}

.contentThatMakesYouContentHeadingAndPara p {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.contentThatMakesYouContentHeadingAndPara
  .seventhSectionImgIconAndParaInOneLine {
  font-weight: 700;
  font-size: 19px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.contentThatMakesYouContentHeadingAndPara .seventhSectionImgOkIcon img {
  width: 25px;
}

.contentThatMakesYouContentHeadingAndPara .seventhSectionSecondParaInOneLine {
  margin-left: 7px;
}

.contentThatMakesYouContentImgDiv img {
  position: relative;
  width: 650px;
  right: 100px;
  transform: translate(10px, -58px);
}

/* Responsive Seventh Section Start Here */
@media only screen and (max-width: 1199px) {
  .contentThatMakesYouContentImgDiv img {
    width: 600px;
  }
}
@media only screen and (max-width: 991px) {
  .seventhSectionOfLandingPageStartHere {
    padding: 5% 2rem 0 2rem;
  }
  .container.seventhSectionOfLandingPageInnerDivStartHere {
    max-width: 100%;
  }
  .contentThatMakesYouContentImgDiv img {
    width: 550px;
    padding-top: 2rem;
    transform: translate(25px, -58px);
  }
  .seventhSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
}
@media only screen and (max-width: 900px) {
  .contentThatMakesYouContentImgDiv img {
    width: 500px;
  }
}
@media only screen and (max-width: 800px) {
  .contentThatMakesYouContentImgDiv img {
    width: 450px;
    padding-top: 6rem;
  }
  .contentThatMakesYouContentHeadingAndPara .seventhSectionSecondHeading {
    line-height: 45px;
    margin-top: -9px;
  }
}
@media only screen and (max-width: 768px) {
  .contentThatMakesYouContentImgDiv img {
    padding-top: 9rem;
  }
}
@media only screen and (max-width: 767px) {
  .contentThatMakesYouContentHeadingAndPara .seventhSectionFirstHeading,
  .contentThatMakesYouContentHeadingAndPara .seventhSectionSecondHeading {
    text-align: center;
  }
  .contentThatMakesYouContentImgDiv img {
    width: 500px;
    padding-top: 1rem;
    transform: translate(0rem, 0rem);
    display: flex;
    right: 0;
    margin: auto;
  }
  .container.third-question-first-inner-div, .container.fourth-question-first-inner-div {
    max-width: 100%;
    padding: 0 2rem;
}
}
@media only screen and (max-width: 528px) {
  .seventhSectionOfLandingPageStartHere {
    top: 0rem;
  }
}
@media only screen and (max-width: 524px) {
  .contentThatMakesYouContentImgDiv img {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .seventhSectionOfLandingPageStartHere {
    padding: 5% 1rem 0 1rem;
  }
  .container.third-question-first-inner-div, .container.fourth-question-first-inner-div {
    padding: 0 1rem;
}
}

/* Old Code of Ninth Section Start Here */
/* .ninthSectionOfLandingPageStartHere {
  padding: 5% 0 5% 0;
}

.signUpForNewsLetterHeadingAndButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signUpForNewsLetterHeadingAndButton h2 {
  font-weight: 600;
  font-size: 39px;
  line-height: 55px;
  color: #000000;
}

.signUpForNewsLetterHeadingAndButton button {
  background: #000000;
  border-radius: 20px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  padding: 0.7rem 3rem;
  margin-top: 19px;
  font-family: var(--font-family-4);
  border: none;
  outline: none;
} */

/* Responsive Old Code of Ninth Section Start Here */
/* @media only screen and (max-width: 991px) {
  .ninthSectionOfLandingPageStartHere {
    position: relative;
    top: 0rem;
  }
}
@media only screen and (max-width: 767px) {
  .ninthSectionOfLandingPageStartHere {
    padding: 5% 0 2.5% 0;
  }
  .signUpForNewsLetterHeadingAndButton h2 {
    font-size: 29px;
}
}
@media only screen and (max-width: 524px) {
  .signUpForNewsLetterHeadingAndButton h2 {
    text-align: center;
  }
}
@media only screen and (max-width: 375px) {
  .signUpForNewsLetterHeadingAndButton h2 {
    font-size: 27px;
  }
  .signUpForNewsLetterHeadingAndButton button {
    font-size: 18px;
  }
} */

/* Old Code of Ninth Section End Here */

/* Responsive Page for 4th Section Remaining */
@media only screen and (max-width: 612px) {
  .bestContentsGetFromUsHeadingDiv h6 {
    line-height: 43px;
    margin-top: -11px;
    margin-bottom: 23px;
  }
}
