/* Eighth Section Start Here */
.testimonialsHeadingDiv h2 {
  font-weight: 700;
  font-size: 33px;
  line-height: 82px;
  color: #000000;
  text-align: center;
  margin-bottom: 1.5rem;
}

.eighthSectionOfLandingPageStartHere {
  background: rgba(255, 216, 73, 0.5);
  padding: 0 0 2rem 0;
}

.testimonialHeadingAndPara {
  padding-top: 5rem;
}

.testimonialHeadingAndPara img {
  width: 85px;
}

.testimonialHeadingAndPara h4 {
  font-weight: 700;
  font-size: 35px;
  line-height: 51px;
  color: #000000;
  margin: 2.5rem 0 2rem 0;
}

.testimonialHeadingAndPara p {
  font-weight: 700;
  font-size: 21px;
  line-height: 43px;
  color: #4b4b4b;
}

/* Swiper Prev and Next Button CSS Start Here */
.swiper-button-next,
.swiper-button-prev {
  top: 50% !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 5px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 5px !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 39px !important;
  height: 39px !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
  background-image: url("../../Image/Landing_Page/Testimonials/Left.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100% !important;
  height: 100% !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
  background-image: url("../../Image/Landing_Page/Testimonials/Right.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 75px;
  height: 75px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: none !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 55px !important;
}
/* Swiper Prev and Next Button CSS End Here */

/* Pagination Start Here */
.swiper-pagination-bullet {
  width: 18px !important;
  height: 18px !important;
}
/* Pagination End Here */

/* Swiper Slider CSS Start Here */
.carouselContainerDiv {
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
  padding: 4rem 1rem 3rem 1rem;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  border-radius: 17px;
}

.carouselItem {
  background-color: #ffffff;
  border-radius: 17px;
  min-width: 339px;
  max-width: 339px;
  height: 100%;
}

.carousel-heading-and-para {
  padding: 1rem;
}

.carousel-heading-and-para .carouselTestimonial {
  font-weight: 400;
  font-size: 17px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.carousel-heading-and-para .testimonialName {
  font-weight: 600;
  font-size: 19px;
  line-height: 35px;
  color: #000000;
}

.carousel-heading-and-para .testimonialCompany {
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #4b4b4b;
}
/* Swiper Slider CSS End Here */

/* Responsive Eighth Section Start Here */
@media only screen and (max-width: 1199px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 35px;
    height: 35px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 2px !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 2px !important;
  }
  .carouselItem {
    min-width: 315px !important;
    max-width: 315px !important;
  }
}
@media only screen and (max-width: 1105px) {
  .carouselItem {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 991px) {
  .carouselItem {
    min-width: 285px !important;
    max-width: 285px !important;
  }
  .carouselContainerDiv {
    height: 100%;
  }
  .swiper {
    padding: 2rem 1rem 3rem 1rem;
  }
  .testimonialHeadingAndPara p {
    padding-left: 5rem;
  }
  .testimonialHeadingAndPara h4 {
    margin: 2.5rem 0 1rem 0;
    padding-left: 5rem;
  }
  .testimonialHeadingAndPara img {
    margin-left: 3rem;
  }
}
@media only screen and (max-width: 924px) {
  .carouselItem {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 575px) {
    .eighthSectionOfLandingPageStartHere {
        padding: 0 2rem 2rem 2rem;
    }
    .carouselItem img {
        width: 100%;
    }
    .testimonialHeadingAndPara h4 {
        font-size: 27px;
        line-height: 43px;
    }
    .testimonialHeadingAndPara img {
        margin-left: 0rem;
        width: 51px;
    }
    .testimonialHeadingAndPara p {
        font-size: 19px;
        line-height: 35px;
    }
    .testimonialHeadingAndPara p,
    .testimonialHeadingAndPara h4 {
        padding-left: 0rem;
    }
}
@media only screen and (max-width: 425px) {
    .eighthSectionOfLandingPageStartHere {
        padding: 0 0.5rem 2rem 0.5rem;
    }
}
