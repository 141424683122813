
.ourWorkSamplesHeadingSection {
    padding-bottom: 3rem;
}

.container.carouselSliderFirstInnerDiv {
    padding: 0rem 0;
}

.ourWorkSamplesHeadingDiv p {
    font-weight: 700;
    font-size: 21px;
    line-height: 41px;
    color: #c08b00;
    text-align: center;
}

.ourWorkSamplesHeadingDiv h6 {
    font-weight: 700;
    font-size: 33px;
    line-height: 71px;
    color: #000000;
    margin-top: -30px;
    text-align: center;
}

.carousel_slider_section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: -4rem;
    /* background: #FFD84A; */
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiperSlide {
  background-position: center;
  background-size: cover;
  /* width: 300px; */
  /* height: 300px; */
}

.swiperSlide {
    position: relative;
    padding: 40px;
    width: 501px;
    height: 301px;
    filter: blur(2px);
}

.swiper-slide.swiper-slide-visible.swiper-slide-active {
    filter: blur(0px) !important;
}

.swiperSlide img {
    position: absolute;
    /* width: 100%; */
    height: 100%;
    left: 0;
    top: 0;
    /* border-radius: 13px; */
    object-fit: contain;
}

.swiperSlide .nexus {
    border: 3px solid #343434;
    border-radius: 13px;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

/* Reponsive Component Start Here */
@media only screen and (max-width: 767px) {
    .container.carouselSliderFirstInnerDiv {
        max-width: 100%;
    }
    .swiperSlide img {
        width: 100%;
    }
    .swiperSlide .nexus {
        border: none;
        border-radius: 0px;
    }
    .swiperSlide {
        width: 301px;
        height: 301px;
    }
    .carousel_slider_section {
       padding: 0 1rem;
       margin-top: -8rem;
    }
}
@media only screen and (max-width: 499px) {
    .swiperSlide {
        width: 100%;
    }
}
