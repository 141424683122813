
.sitemap-section-start-here {
    padding: 2rem 2rem;
}

.sitemap-main-div li {
    margin: 1.5% 0;
    color: #000000;
    font-weight: 500;
    cursor: pointer;
    font-size: 19px;
    line-height: 39px;
    margin-bottom: 0.5rem;
}

.listItem {
    text-decoration: none;
    color: #000000;
    font-weight: 600;
}

.listItem:hover {
    border-bottom: 2px solid #000000;
    color: #000000;
}

@media only screen and (max-width: 575px) {
    .sitemap-section-start-here {
        padding: 2rem 0rem;
    }
}