
.faq-heading-and-banner-image-section-start-here {
    padding: 2rem 2rem 3rem 2rem;
}

.faq-heading-and-para-div {
    padding-top: 4rem;
}

.faq-heading-and-para-div h1 {
    font-weight: 700;
    line-height: 47px;
    padding-bottom: 1rem;
}

.faq-heading-and-para-div p {
    color:#393939;
    font-size: 21px;
    line-height: 35px;
    padding-right: 3rem;
    font-weight: 500;
    text-align: justify;
}

.main-faq-section-start-here {
    padding: 0 2rem 3rem 2rem;
}

.faq-heading-and-banner-first-inner-div {
    background: #FFFFFF;
    box-shadow: -1px 9px 17px 4px #6D6D6D33;
    padding: 2rem;
}

.faq-heading-and-banner-second-inner-div {
    padding: 0 2rem;
}

.faq-questions-heading-div {
    margin-left: 0rem;
}

.faq-questions-heading-div p:first-of-type {
    margin-top: 1.5rem;
}

.faq-questions-and-answers h2 {
    font-weight: 600;
}

.faq-questions-heading-div p {
    margin-bottom: 1.5rem;
    line-height: 35px;
    color: #000000;
    font-weight: 600;
    font-size: 19px;
}

.faq-questions-heading-div span {
    cursor: pointer;
}

@media only screen and (max-width: 1100px) {
    .faq-heading-and-para-div {
        padding-top: 2rem;
    }
}
@media only screen and (max-width: 992px) {
    .faq-heading-and-para-div {
        padding-top: 1rem;
    }
}
@media only screen and (max-width: 850px) {
    .faq-heading-and-para-div h1 {
        padding-bottom: 0.5rem;
    }
    .faq-heading-and-para-div p {
        padding-right: 0rem;
    }
    .faq-heading-and-para-div {
        padding-top: 0rem;
    }
}
@media only screen and (max-width: 767px) {
    .main-faq-section-start-here {
        padding: 0 1rem 3rem 1rem;
    }
    .faq-questions-heading-div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .faq-questions-heading-div p {
        margin: 0rem 1rem 1rem 1rem;
    }
    .faq-questions-heading-div p:first-of-type {
        margin-top: 0rem;
    }
}
@media only screen and (max-width: 425px) {
    .faq-questions-heading-div p {
        font-size: 17px;
    }
    .faq-heading-and-banner-second-inner-div {
        padding: 0 1rem;
    }
}