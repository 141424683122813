.firstSectionOfContactUsPageStartHere {
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
  width: 100%;
  height: auto;
  padding: 0 3% 5.5% 3%;
}

.contactUsBannerImgDiv img {
  padding-top: 3%;
}

.contactUsFormDiv h2 {
  font-weight: 700;
  font-size: 29px;
  line-height: 71px;
  color: #000000;
  margin-top: 19px;
  margin-bottom: 19px;
}

.getQuoteAndMailUsButtonsDiv {
  padding-top: 8rem;
}

.getAQuoteDiv h4, .mailUsDiv h4 {
  font-weight: 600;
  font-size: 23px;
  line-height: 48px;
  color: #000000;
}

.getAQuoteDiv button, .mailUsDiv button {
  border-radius: 90px;
  font-weight: 700;
  font-size: 20px;
  padding: 0.5rem 3rem;
  outline: none;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.getAQuoteDiv button {
  background: #000000;
  color: #FFFFFF;
  border: none;
}

.mailUsDiv button {
  background: transparent;
  color: #000000;
  border: 3px solid #000000;
}

.formsInnerDiv label {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #3c3c3c;
}

.formsInnerDiv input {
  background: #ffffff;
  border-radius: 7px;
  outline: none;
}

.formsInnerDiv button {
  background: #000000;
  border-radius: 58px;
  font-weight: 700;
  font-size: 19px;
  padding: 0.5rem 3rem;
  color: #ffffff;
  margin-top: 11px;
  border: none;
  outline: none;
}

.formsBlogsArticlesAndWords .formsBlogsArticlesAndWordsFirstInnerDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.formsNoOfBlogs input,
.formsNoOfWords input {
  width: 149px;
  height: 55px;
  text-align: center;
  font-size: 25px;
  border: none;
  color: #000000;
}

.formsInnerDiv button {
  display: flex;
  margin: auto;
}

.formsNoOfBlogs,
.formsNoOfWords {
  position: relative;
}

.formsNoOfWords {
  right: 0em;
}

.formsNoOfBlogs .blogsDash {
  position: absolute;
  transform: translate(0.7rem, 0.9rem) scale(1.5);
  cursor: pointer;
}

.formsNoOfBlogs .blogsPlus {
  position: absolute;
  transform: translate(7.6rem, -2.55rem) scale(1.5);
  cursor: pointer;
}

.formsNoOfWords .wordsDash {
  position: absolute;
  transform: translate(0.7rem, 0.9rem) scale(1.5);
  cursor: pointer;
}

.formsNoOfWords .wordsPlus {
  position: absolute;
  transform: translate(7.6rem, -2.55rem) scale(1.5);
  cursor: pointer;
}

.moveToNewForm p {
  font-weight: 400;
  font-size: 19px;
  line-height: 39px;
  color: #000000;
}

.moveToNewForm .mailUs {
  cursor: pointer;
  color: #335DF4;
  font-weight: 700;
  border-bottom: 2.5px solid #000000;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
  .getAQuoteDiv h4, .mailUsDiv h4 {
    line-height: 39px;
}
}
@media only screen and (max-width: 1100px) {
    .contactUsBannerImgDiv img {
        padding-top: 10%;
    }
}
@media only screen and (max-width: 1000px) {
    .contactUsBannerImgDiv img {
        padding-top: 11%;
    }
    .contactUsFormDiv h2 {
        line-height: 43px;
        margin-bottom: 2.5rem;
    }
}
@media only screen and (max-width: 992px) {
  .contactUsBannerImgDiv img {
    padding-top: 15%;
  }
}
@media only screen and (max-width: 913px) {
    .contactUsBannerImgDiv img {
        padding-top: 24%;
        width: -webkit-fill-available;
    }
}
@media only screen and (max-width: 800px) {
    .contactUsBannerImgDiv img {
        padding-top: 34%;
        position: relative;
        top: -7rem;
        height: -webkit-fill-available;
    }
}
@media only screen and (max-width: 767px) {
    .firstSectionOfContactUsPageStartHere {
        padding: 0 5% 5.5% 5%;
    }
    .contactUsBannerImgDiv img {
        padding-top: 0%;
        margin-bottom: 5%;
        top: 1rem;
    }
    .formsBlogsArticlesAndWords .formsBlogsArticlesAndWordsFirstInnerDiv {
        justify-content: space-around;
    }
    .getQuoteAndMailUsButtonsDiv {
      padding-top: 1rem;
    }
    .getQuoteAndMailUsButtonsDiv {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .getAQuoteDiv h4, .mailUsDiv h4 {
      text-align: center;
    }
}
@media only screen and (max-width: 425px) {
  .getAQuoteDiv h4, .mailUsDiv h4 {
    font-size: 20px;
  }
  .getAQuoteDiv button, .mailUsDiv button {
    font-size: 18px;
  }
}
