
.secondSecondOfIndividualBlogPageStartHere {
    padding: 2rem 0 0.5rem 0;
}

.blogContent p, .blogContent ul li, .blogContent ol li {
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    text-align: justify;
    color: #262626;
}

.blogContent p a {
    color: #0d6efd;
    text-decoration: none;
}

.blogContent p:first-of-type {
    margin-top: 2.5rem;
}

.blogContent p:last-of-type {
    margin-bottom: 2.5rem;
}

.blogContent h3 {
    padding: 0.7rem 0;
}

.blogContent h3, .comment-section-form-first-inner-div h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 47px;
    text-align: justify;
    color: #000000;
}

.blogContent h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 47px;
    text-align: justify;
    color: #000000; 
}

.blogImageDiv3 img {
    display: flex;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.blogContent table thead th {
    font-size: 22px;
    text-align: center;
    vertical-align: middle;
}

.blogContent table tbody tr td {
    text-align: center;
    font-size: 17px;
    vertical-align: middle;
}

.blog-image-banner-for-side img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.table-of-content-div ol li a {
    text-decoration: none;
    color: #262626;
}

.table-of-content-div ol li::marker {
    color: #262626;
}

.table-of-content-div ol li a:hover {
    color: #eaa800;
    text-decoration: underline;
}

/* Blog Comment Section */
.commnet-section-form-div {
    padding: 2rem 0 3rem 0;
}

.comment-section-form-first-inner-div h3 {
    padding-bottom: 0.7rem;
}

.comment-section-form-first-inner-div form textarea, .comment-section-form-first-inner-div form input {
    border: 2px solid #000000;
    padding: 0.7rem;
    font-size: 19px;
}

.comment-section-form-first-inner-div form input::placeholder, .comment-section-form-first-inner-div form textarea::placeholder {
    color: #262626;
}

.comment-section-form-first-inner-div form textarea:focus, .comment-section-form-first-inner-div form input:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #000000;
}

.comment-section-form-first-inner-div form .post-comment-btn {
    background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
    border: 2px solid #000000;
    color: #262626;
    font-weight: 600;
    font-size: 19px;
    padding: 0.7rem 2rem;
    transition: all 0.5s ease-in-out;
}

.comment-section-form-first-inner-div form .post-comment-btn:hover {
    background: #FFFFFF;
}

/* Blog Comment Reply */
.comment-reply-first-inner-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.user-icon-div img {
    width: 49px;
    height: auto;
}

.user-comments-div .users-name {
    margin-bottom: 0.3rem;
    font-size: 21px;
    font-weight: 600;
}

.user-comments-div .users-comment {
    text-align: justify;
    line-height: 31px;
}

/* Responsive Page of Blog Page Start Here */
@media only screen and (max-width: 575px) {
    .secondSecondOfIndividualBlogPageStartHere {
        padding: 2rem 0.5rem 0.5rem 0.5rem;
    }
}