
/* Second Section of CSS Start Here */
.secondSectionOfCategoriePageHeadingDivStartHere {
  padding: 2rem 0 0rem 0;
}

.selectServicesDivOfHeading p,
.howDoWeHeadingDiv p {
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  color: #c08b00;
  text-align: center;
}

.selectServicesDivOfHeading h6,
.howDoWeHeadingDiv h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 0px;
}

.secondSectionOfCategoriePageStartHere {
  background-image: url("../../Image/Categories_Page/Select_Services.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 600px;
  width: 100%;
  padding: 2% 0 4% 0;
}

.selectServicesDivButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectServicesBtn {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 23px;
  line-height: 62px;
  color: #4e4e4e;
  cursor: pointer;
}

.selectServicesDesignBtn {
  background: #ffe482;
  box-shadow: 3px 7px 19px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 0.6rem 1.7rem;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
}

.selectServicesDivContent,
.engageYourCustomersHeadingAndPara {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selectServicesDivContent h6 {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  line-height: 41px;
  color: #000000;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.selectServicesDivContent .selectServicesContentPara {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: #000000;
}

.learn-more-and-contact-us-btn {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.learn-more-and-contact-us-btn .selectServicesContactUsBtn {
  background: #000000;
  border-radius: 52px;
  font-weight: 700;
  font-size: 19px;
  color: #ffffff;
  padding: 0.5rem 2.5rem;
  margin-left: 3rem;
  border: none;
  outline: none;
}

.selectServicesDivContent a {
  text-decoration: none;
}

.selectServicesDivContent .selectServicesLearnMoreBtn {
  font-weight: 700;
  font-size: 21px;
  line-height: 39px;
  color: #000000;
}

.selectServicesDivHideContent {
  display: none;
}

.selectServicesDivShowContent {
  display: block;
}

/* Responsive Page of Second Section Start Here */
@media only screen and (max-width: 991px) {
  .container.secondSectionOfCategoriePageFirstInnerDiv {
    max-width: 100%;
  }
  .secondSectionOfCategoriePageStartHere {
    padding: 0% 0 4% 0;
  }
}
@media only screen and (max-width: 883px) {
  .learn-more-and-contact-us-btn .selectServicesContactUsBtn {
    margin-left: 1.5rem;
}
}
@media only screen and (max-width: 825px) {
  .learn-more-and-contact-us-btn .selectServicesContactUsBtn {
    margin-top: 1rem;
    margin-left: 0rem;
}
.learn-more-and-contact-us-btn {
  flex-direction: column;
}
}
@media only screen and (max-width: 767px) {
  .selectServicesDivButton {
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}
.secondSectionOfCategoriePageStartHere {
  padding: 0% 2.5rem 0% 2.5rem;
}
.learn-more-and-contact-us-btn .selectServicesContactUsBtn {
  margin-top: 0rem;
  margin-left: 2rem;
}
.learn-more-and-contact-us-btn {
flex-direction: row;
}
}
@media only screen and (max-width: 432px) {
  .learn-more-and-contact-us-btn .selectServicesContactUsBtn {
    margin-top: 0rem;
    margin-left: 1.3rem;
  }
}
@media only screen and (max-width: 425px) {
  .secondSectionOfCategoriePageStartHere {
    padding: 0% 1rem 0% 1rem;
}
  .learn-more-and-contact-us-btn .selectServicesContactUsBtn {
    margin-top: 1rem;
    margin-left: 0rem;
  }
  .learn-more-and-contact-us-btn {
  flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .selectServicesBtn {
    font-size: 21px;
}
}
@media only screen and (max-width: 375px) {
  .selectServicesBtn {
    font-size: 19px;
}
.selectServicesDesignBtn {
  padding: 0.6rem 1rem;
}
}

/* Third Section of CSS Start Here */
.engageYourCustomersHeadingAndPara .headingFirstPara {
  font-weight: 700;
  font-size: 21px;
  line-height: 39px;
  color: #c08b00;
}

.engageYourCustomersHeadingAndPara .headingSecondPara {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  margin-top: -30px;
  margin-bottom: 25px;
}

.engageYourCustomersHeadingAndPara .iconAndContentPara {
  font-weight: 400;
  font-size: 18px;
  line-height: 39px;
  color: #000000;
}

.engageYourCustomersHeadingAndPara .iconAndContentPara img {
  width: 25px;
}

.engageYourCustomersHeadingAndPara .contentSpan {
  margin-left: 0.5rem;
}

/* Responsive Page of Third Section Start Here */
@media only screen and (max-width: 991px) {
  .container.thirdSectionOfCategoriePageFirstInnerDiv {
    max-width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .engageYourCustomersImgDiv img {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 767px) {
  .thirdSectionOfCategoriePageStartHere {
    padding: 0 2.5rem 1.5rem 2.5rem;
}
  .engageYourCustomersImgDiv img {
    padding-top: 0rem;
    width: 500px;
    display: flex;
    justify-content: center;
    margin: auto;
}
.engageYourCustomersHeadingAndPara .headingFirstPara, .engageYourCustomersHeadingAndPara h6 {
  text-align: center;
}
}
@media only screen and (max-width: 425px) {
  .thirdSectionOfCategoriePageStartHere {
    padding: 2rem 1rem 1.5rem 1rem;
}
  .engageYourCustomersHeadingAndPara .headingSecondPara {
    line-height: 47px;
    margin-top: -20px;
}
}

/* Fourth Section of CSS Start Here */
.fourthSectionOfCategoriePageStartHere {
  padding: 5% 0;
}

.howDoWeHeadingDiv h6 {
  margin-bottom: 59px;
}

.howDoWeCardDiv {
  margin: 5rem 0;
  /* height: 100%; */
}

.howDoWeCardInnerDiv {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 2rem 2rem;
  margin: 0rem 0;
  height: 100%;
}

.howDoWeCardInnerDiv img {
  transform: translate(-2rem, -6rem) scale(0.7);
  height: auto;
  position: absolute;
}

.howDoWeCardInnerDiv h6 {
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  line-height: 41px;
  color: #000000;
}

.howDoWeCardInnerDiv p {
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  text-align: justify;
  color: #000000;
}

/* Responsive Page of Fourth Section Start Here */
@media only screen and (max-width: 1199px) {
  .howDoWeCardInnerDiv img {
    transform: translate(-3rem, -6.5rem) scale(0.65);
  }
}
@media only screen and (max-width: 575px) {
  .fourthSectionOfCategoriePageStartHere {
    padding: 5% 5%;
}
}
@media only screen and (max-width: 425px) {
  .fourthSectionOfCategoriePageStartHere {
    padding: 5% 1%;
}
  .howDoWeHeadingDiv h6 {
    line-height: 47px;
    margin-top: -10px;
}
.selectServicesDivOfHeading h6, .howDoWeHeadingDiv h6, .engageYourCustomersHeadingAndPara .headingSecondPara {
  font-size: 28px;
}
}
@media only screen and (max-width: 350px) {
  .howDoWeCardInnerDiv img {
    transform: translate(-3rem, -7rem) scale(0.60);
}
}

/* Fifth Section of CSS Start Here */
.videoDivInCategoriePage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoDivInCategoriePage img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center center;
}

/* Responsive Page of Fifth Section Start Here */
@media only screen and (max-width: 991px) {
  .videoDivInCategoriePage img {
    height: 301px;
  }
}
@media only screen and (max-width: 767px) {
  .container.fifthSectionOfCategoriesPageFirstInnerDiv {
    max-width: 100%;
  }
  .videoDivInCategoriePage img {
    height: auto;
  }
}

/* Sixth Section of CSS Start Here */
.sixthSectionOfCategoriePageStartHere {
  background: #ffe589;
  padding: 5% 0;
}

.getInTouchFormInputAndBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.getInTouchFormInputAndBtnDiv h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 71px;
  color: #000000;
}

/* Responsive Page of Sixth Section Start Here */
@media only screen and (max-width: 991px) {
  .sixthSectionOfCategoriePageStartHere {
    padding: 7% 0;
  }
  .getInTouchFormInputAndBtnDiv h2 {
    font-size: 31px;
    line-height: 49px;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .getInTouchFormInputAndBtnDiv h2 {
    text-align: center;
}
}
@media only screen and (max-width: 425px) {
  .getInTouchFormInputAndBtnDiv h2 {
    font-size: 28px;
}
}
