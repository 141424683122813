.firstSectionOfTermsOfServicePageStartHere {
  padding: 5% 0 3% 0;
}

.termsOfServicesHeadingDiv p {
  font-weight: 700;
  font-size: 21px;
  line-height: 21px;
  color: #c08b00;
}

.termsOfServicesHeadingDiv h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 49px;
  color: #000000;
  margin-bottom: 39px;
  margin-top: -20px;
}

.termsOfServicesMainContentDiv h3 {
  font-weight: 700;
  font-size: 23px;
  line-height: 41px;
  color: #000000;
}

.termsOfServicesMainContentDiv p {
  font-weight: 500;
  font-size: 19px;
  line-height: 39px;
  color: #000000;
  text-align: justify;
  margin-bottom: 2.5rem;
}

.secondSectionOfTermsOfServicePageStartHere {
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
  padding: 3% 0;
}

.termsOfServicesEmailDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.termsOfServicesEmailDiv .contactInformationPara {
  font-weight: 700;
  font-size: 23px;
  line-height: 39px;
  color: #000000;
}

.termsOfServicesEmailDiv .pleaseContactPara {
  font-weight: 500;
  font-size: 21px;
  line-height: 39px;
  color: #000000;
}

.termsOfServicesEmailDiv .emailPara {
  font-weight: 700;
  font-size: 25px;
  line-height: 39px;
  color: #000000;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 575px) {
  .firstSectionOfTermsOfServicePageStartHere {
    padding: 5% 3% 3% 3%;
  }
  .termsOfServicesEmailDiv .pleaseContactPara {
    text-align: center;
}
}
@media only screen and (max-width: 375px) {
  .termsOfServicesEmailDiv .emailPara {
    font-size: 22px;
}
}
