/* CSS of First Section Start Here */
.firstSectionOfProductDescriptionPageStartHere {
  padding: 2.5rem 0 2.5rem 0;
  background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
}

.productDescriptionHeadingAndParaDiv {
  padding-top: 0rem;
}

.productDescriptionHeadingAndParaDiv h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 59px;
  color: #000000;
  text-align: justify;
}

.productDescriptionHeadingAndParaDiv p {
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
  text-align: justify;
}

/* Responsive Page's CSS of First Section Start Here */
@media only screen and (max-width: 1200px) {
  .productDescriptionHeadingAndParaDiv {
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 991px) {
  .productDescriptionHeadingAndParaDiv {
    padding-top: 0rem;
  }
}
@media only screen and (max-width: 950px) {
  .productDescriptionHeadingAndParaDiv h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 800px) {
  .productDescriptionHeadingAndParaDiv h1 {
    font-size: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .firstSectionOfProductDescriptionPageStartHere {
    padding: 2.5rem 3rem;
  }
  .productDescriptionHeadingAndParaDiv h1 {
    line-height: 47px;
}
  .productDescriptionHeadingAndParaDiv h1,
  .productDescriptionHeadingAndParaDiv p {
    text-align: center;
  }
}
@media only screen and (max-width: 425px) {
  .firstSectionOfProductDescriptionPageStartHere {
    padding: 0.5rem 0.5rem;
}
}

/* CSS of Second Section Start Here */
.secondPageOfProductDescriptionPageStartHere {
  padding-bottom: 2rem;
}

.categoriesServicesSecondSectionHeadingAndParaDiv {
  padding-top: 3rem;
}

.categoriesServicesSecondSectionHeadingAndParaDiv .servicesHeadingPara,
.thirdSectionHeading p, .first-question-heading-div p, .second-question-heading-div p, .third-question-heading-div p {
  font-weight: 700;
  font-size: 21px;
  line-height: 41px;
  color: #c08b00;
}

.categoriesServicesSecondSectionHeadingAndParaDiv h6,
.thirdSectionHeading h6, .first-question-heading-div h6, .second-question-heading-div h6, .third-question-heading-div h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  margin-top: -30px;
}

/* Questions CSS */
.first-question-section-start {
  padding-bottom: 1rem;
}

.second-question-section-start, .third-question-section-start {
  padding-bottom: 2rem;
}

.first-question-heading-div p, .first-question-heading-div h6, .second-question-heading-div p, .second-question-heading-div h6, .third-question-heading-div p, .third-question-heading-div h6 {
  text-align: center;
}

.categoriesServicesSecondSectionHeadingAndParaDiv .servicesPara, .first-question-para-div p, .second-question-para-div p, .third-question-para-div p {
  font-weight: 500;
  font-size: 19px;
  line-height: 35px;
  text-align: justify;
  color: #393939;
}

/* Responsive Page's CSS of Second Section Start Here */
@media only screen and (max-width: 1199px) {
  .categoriesServicesSecondSectionHeadingAndParaDiv {
    padding-top: 1rem;
  }
  .categoriesServicesSecondSectionHeadingAndParaDiv h6,
  .thirdSectionHeading h6 {
    line-height: 47px;
    margin-top: -19px;
  }
}
@media only screen and (max-width: 991px) {
  .secondPageOfProductDescriptionPageStartHere {
    padding: 2rem 2rem 4rem 2rem;
}
  .container.secondSectionOfProductDescriptionContainerDiv, .container.secondPageOfProductDescriptionFirstInnerDiv {
    max-width: 100%;
  }
  .categoriesServicesSecondSectionImgDiv img {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}
@media only screen and (max-width: 767px) {
  .secondPageOfProductDescriptionPageStartHere {
    padding: 0 1rem 4rem 1rem;
}
.categoriesServicesSecondSectionHeadingAndParaDiv .servicesHeadingPara, .categoriesServicesSecondSectionHeadingAndParaDiv h6 {
  text-align: center;
}
}
@media only screen and (max-width: 375px) {
  .secondPageOfProductDescriptionPageStartHere {
    padding: 0 1rem 4rem 1rem;
}
}

/* CSS of Third Section Start Here */
.thirdSectionOfProductDescriptionPageHeadingStartHere {
  padding-bottom: 0rem;
}

.thirdSectionHeading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.thirdSectionOfProductDescriptionPageStartHere {
  background-image: url("../../../Image/Categories_Page/Select_Services.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 600px;
  width: 100%;
  padding: 5% 0;
}

.thirdSectionCardDiv {
  background: #ffffff;
  box-shadow: 0px 9px 17px rgba(109, 109, 109, 0.23);
  border-radius: 15px;
  padding: 1rem 1rem;
  height: 100%;
}

.thirdSectionCardDiv img {
  width: 69px;
  margin-bottom: 15px;
}

.thirdSectionCardDiv h6 {
  font-weight: 700;
  font-size: 19px;
  line-height: 29px;
  color: #000000;
}

.thirdSectionCardDiv p {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  text-align: justify;
  color: #858585;
}

/* Responsive Page's CSS of Third Section Start Here */
@media only screen and (max-width: 575px) {
  .thirdSectionOfProductDescriptionPageStartHere {
    background-size: cover;
}
  .thirdSectionHeading h6 {
    text-align: center;
  }
  .throughOurContentCardDiv {
    padding: 0 6rem;
}
}
@media only screen and (max-width: 425px) {
  .throughOurContentCardDiv {
    padding: 0 3rem;
}
}

/* CSS of Fifth Section Start Here */
.fifthSectionOfProductionDescriptionPageStartHere {
  background: #ffe589;
  padding: 5% 0;
  margin-top: 3rem;
}

.subscribeFormDivUnderProductDescriptionPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subscribeFormDivUnderProductDescriptionPage h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 71px;
  color: #000000;
}

/* Responsive Page's CSS of Fifth Section Start Here */
@media only screen and (max-width: 767px) {
  .subscribeFormDivUnderProductDescriptionPage h2 {
    font-size: 29px;
    line-height: 63px;
}
}
@media only screen and (max-width: 425px) {
  .fifthSectionOfProductionDescriptionPageStartHere {
    padding: 9% 0;
}
  .subscribeFormDivUnderProductDescriptionPage h2 {
    line-height: 49px;
    text-align: center;
}
}