/* Heading Div of Blog Page Start Here */
.headingSectionOfBlogPageStartHere {
    padding: 2rem 0 0 0;
}

.headingAndSearchInputDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    transform: translate(13rem, 0rem);
}

.searchInputDiv .searchIcon {
    position: absolute;
    font-size: 19px;
    transform: translate(1rem, 0.6rem);
}

.searchInputDiv input {
    padding: 0.7rem 3rem;
    width: 351px;
}

.searchInputDiv input, .searchInputDiv input:focus {
    background: #FFFFFF;
    border: 2px solid #747474;
    border-radius: 9px;
}

.searchInputDiv input:focus {
    box-shadow: none;
}

.searchInputDiv input::placeholder {
    font-weight: 400;
    font-size: 18px;
    color: #747474;
}

/* First Section of Blog Page CSS Start Here */
.firstSectionOfBlogPageStartHere {
    padding: 3rem 0 3rem 0;
}

.firstSectionOfBlogPageFirstInnerDiv {
    border-bottom: 2px solid #000000;
}

.firstSectionOfBlogPageSecondInnerDiv {
    padding: 0 0 1.5rem 0;
}

.leftBlogCardHeadingAndPara .leftCardAuthorNameAndDate, .rightCardHeadingAndPara .rightCardAuthorNameAndDate {
    font-weight: 700;
    font-size: 17px;
    line-height: 35px;
    text-align: justify;
    color: #555555;
}

.leftBlogCardHeadingAndPara .leftCardAuthorNameAndDate {
    margin-top: 0.5rem;
}

.rightCardHeadingAndPara {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.rightCardHeadingAndPara .rightCardAuthorNameAndDate {
    margin-top: -0.5rem;
}

.leftBlogCardHeadingAndPara h3, .rightCardHeadingAndPara h3 {
    font-weight: 700;
    font-size: 23px;
    line-height: 39px;
    text-align: justify;
    margin-top: -15px;
}

.rightCardHeadingAndPara h3 {
    font-size: 19px;
    line-height: 31px;
}

.rightCardHeadingAndPara h3 a {
    text-decoration: none;
    color: #000000;
}

.rightCardHeadingAndPara h3 a:hover {
    border-bottom: 2px solid #000000;
}

.rightCardImgDiv {
    margin-bottom: 1rem;
}

.rightCardImgDiv img {
    width: 151px;
}

.leftBlogCardHeadingAndPara h3 a, .rightCardHeadingAndPara .rightCardPara a {
    text-decoration: none;
    color: #000000;
}

.leftBlogCardHeadingAndPara h3 a:hover, .rightCardHeadingAndPara .rightCardPara a:hover {
    border-bottom: 2px solid #000000;
}

.leftBlogCardHeadingAndPara h3 {
    padding-right: 1rem;
}

.rightCardHeadingAndPara h3 {
    padding-right: 0rem;
}

.leftBlogCardHeadingAndPara .leftCardPara, .rightCardHeadingAndPara .rightCardPara {
    font-weight: 400;
    text-align: justify;
    color: #000000;
}

.leftBlogCardHeadingAndPara .leftCardPara {
    padding-right: 1rem;
    font-size: 19px;
    line-height: 35px;
}

.rightCardHeadingAndPara .rightCardPara {
    padding-right: 0rem;
    font-size: 17px;
    line-height: 21px;
}

/* .latestBlogRightCardInnerDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
} */

.latestBlogRightCardForBelowLine {
    border-bottom: 2px solid #000000;
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
}

/* Second Section of Blog Page CSS Start Here */
.secondSectionOfBlogPageStartHere {
    padding: 0 0 2rem 0;
}

.industryHeadingDiv p, .moreBlogsHeading p, .mainHeadingDiv p {
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    line-height: 43px;
    color: #C08B00;
}

.industryHeadingDiv h3, .moreBlogsHeading h3, .mainHeadingDiv h3 {
    text-align: center;
    font-weight: 700;
    font-size: 33px;
    line-height: 71px;
    color: #000000;
    margin-top: -30px;
}

.chooseIndustryCard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    padding: 2rem 0;
}

.chooseIndustryCard img {
    width: 115px;
}

.chooseIndustryCard a {
    text-decoration: none;
    color: #000000;
    font-weight: 400;
}

.chooseIndustryCard h5 a:hover {
    color: #000000;
    border-bottom: 2px solid #000000;
    font-weight: 600;
}

.chooseIndustryCard h5 {
    font-size: 19px;
    line-height: 34px;
    text-align: center;
    margin-top: 0.5rem;
}

/* Third Section of Blog Page CSS Start Here */
.thirdSectionOfBlogPageStartHere {
    padding: 0rem 0rem 4rem 0rem;
}

.moreBlogsCardForGivingTopMargin {
    margin: 1rem 0;
}

.moreBlogsHeading {
    padding: 0 0 2rem 0;
}

.moreBlogsIndividualCardDiv {
    margin: 1.5rem 0;
}

.moreBlogsIndividualCardImageDiv {
    height: 100%;
}

.moreBlogsIndividualCardHeadingAndParaDiv h3 {
    font-weight: 500;
    font-size: 21px;
    line-height: 38px;
    margin-top: 1rem;
}

.moreBlogsIndividualCardHeadingAndParaDiv h3 a {
    text-decoration: none;
    color: #000000;
}

.moreBlogsIndividualCardHeadingAndParaDiv .moreBlogsPara {
    font-weight: 400;
    font-size: 17px;
    line-height: 31px;
    color: #7E7E7E;
    text-align: justify;
}

.moreBlogsIndividualCardHeadingAndParaDiv .readMoreBlogButton {
    font-weight: 700;
    font-size: 21px;
    line-height: 43px;
}

.moreBlogsIndividualCardHeadingAndParaDiv .readMoreBlogButton a {
    text-decoration: none;
    color: #000000;
}

.moreBlogsIndividualCardHeadingAndParaDiv .readMoreBlogButton a:hover, .moreBlogsIndividualCardHeadingAndParaDiv h3 a:hover {
    border-bottom: 2px solid #000000;
}

.alsoReadHeading h3, .popularArticlesHeading h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 49px;
    color: #000000;
}

.popularArticlesHeading h3 {
    margin-top: 5rem;
}

.alsoReadBlogsImageAndParaDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 1rem 0;
}

.also-read-para-div {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: column;
}

.alsoReadBlogsImageAndParaDiv img {
    width: 111px;
    border-radius: 11px;
}

.alsoReadBlogsImageAndParaDiv p {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    padding-right: 2rem;
    text-align: justify;
    margin-bottom: 0rem;
}

.alsoReadBlogsImageAndParaDiv p a {
    text-decoration: none;
    color: #000000;
}

.alsoReadBlogsImageAndParaDiv .also-read-read-more-btn {
    padding-left: 1rem;
    margin-top: 0.3rem;
}

.alsoReadBlogsImageAndParaDiv p a:hover {
    border-bottom: 2px solid #000000;
}

/* Fourth Section of Blog Page CSS Start Here */
.fourthSectionOfBlogPageStartHere {
    background-color: #FFE589;
    padding: 2rem 0 4rem 0;
}

.contactUsButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactUsButtonDiv h3 {
    font-weight: 700;
    font-size: 29px;
    line-height: 57px;
    color: #000000;
}

.contactUsButtonDiv p {
    font-weight: 400;
    font-size: 22px;
    line-height: 39px;
    text-align: center;
    color: #646464;
    padding: 0 4rem;
}

.contactUsButtonDiv a {
    text-decoration: none;
}

.contactUsButtonDiv button {
    background: #000000;
    border-radius: 20px;
    border: none;
    outline: none;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    padding: 1rem 3rem;
    margin-top: 0.5rem;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .headingAndSearchInputDiv {
        justify-content: space-between;
    }
    .firstSectionOfBlogPageStartHere {
        padding: 3rem 2rem 3rem 2rem;
    }
    .container.firstSectionOfBlogPageFirstInnerDiv, .container.thirdSectionOfBlogPageFirstInnerDiv {
        max-width: 100%;
    }
    .thirdSectionOfBlogPageStartHere {
        padding: 0 2rem 4rem 2rem;
    }
    .alsoReadBlogsImageAndParaDiv p {
        font-size: 18px;
        line-height: 29px;
    }
}
@media only screen and (max-width: 1056px) {
    .headingAndSearchInputDiv {
        transform: translate(10rem, 0rem);
    }
    .alsoReadBlogsImageAndParaDiv p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 991px) {
    .firstSectionOfBlogPageSecondInnerDiv {
        padding: 0 0 1rem 0;
    }
    .headingAndSearchInputDiv {
        flex-direction: column;
        transform: translate(-3rem, 0rem);
    }
    .searchInputDiv input {
        width: 555px;
    }
    .leftCardImage img {
        width: 100%;
        display: flex;
        margin: 0 auto;
    }
    .leftBlogCardHeadingAndPara h3 {
        padding-right: 0.5rem;
        margin-top: -10px;
    }
    .latestBlogRightCardDiv {
        padding: 2rem 2rem 0 2rem;
    }
    .leftBlogCardHeadingAndPara .leftCardPara {
        padding-right: 0.5rem;
        line-height: 35px;
    }
    .latestBlogRightCardForBelowLine {
        margin-bottom: 2rem;
    }
    .rightCardImgDiv img {
        margin-bottom: 1rem;
    }
    .thirdSectionOfBlogPageStartHere {
        padding: 0 5rem 4rem 5rem;
    }
    .alsoReadHeading h3, .popularArticlesHeading h3 {
        font-size: 29px;
        text-align: center;
        padding: 2rem 0;
    }
    .popularArticlesHeading h3 {
        margin-top: 0rem;
    }
    .contactUsButtonDiv p {
        padding: 0 0rem;
    }
    .firstTwoAlsoReadCard {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .alsoReadBlogsImageAndParaDiv img {
        width: 151px;
    }
    .alsoReadBlogsImageAndParaDiv {
        flex-direction: row;
    }
    .alsoReadBlogsImageAndParaDiv p {
        font-size: 18px;
        padding: 0.5rem;
        text-align: justify;
    }
}
@media only screen and (max-width: 767px) {
    .headingAndSearchInputDiv {
        transform: translate(0rem, 0rem);
    }
    .searchInputDiv input {
        width: 500px;
    }
    .secondSectionOfBlogPageStartHere {
        padding: 0 2rem 2rem 2rem;
    }
    .container.secondSectionOfBlogPageFirstInnerDiv {
        max-width: 100%;
    }
    .thirdSectionOfBlogPageStartHere {
        padding: 0 2rem 4rem 2rem;
    }
    .contactUsButtonDiv p {
        font-size: 20px;
    }
}
@media only screen and (max-width: 575px) {
    .searchInputDiv input {
        width: 100%;
    }
}
@media only screen and (max-width: 500px) {
    .firstTwoAlsoReadCard {
        flex-direction: column;
    }
    /* .alsoReadBlogsImageAndParaDiv img {
        width: 100%;
    } */
    .contactUsButtonDiv h3 {
        line-height: 47px;
        text-align: center;
    }
    /* .latestBlogRightCardInnerDiv {
        flex-direction: column;
    } */
    .rightCardImgDiv img {
        width: 500px;
    }
    .rightCardHeadingAndPara h3 {
        line-height: 34px;
    }
    .rightCardHeadingAndPara .rightCardPara {
        line-height: 29px;
    }
    .rightCardHeadingAndPara.ms-4 {
        margin-left: 0rem !important;
    }
}
@media only screen and (max-width: 375px) {
    .headingAndSearchInputDiv {
        padding: 0 2rem;
    }
    .firstSectionOfBlogPageStartHere {
        padding: 3rem 0.5rem 3rem 0.5rem;
    }
    .secondSectionOfBlogPageStartHere {
        padding: 0 0.5rem 2rem 0.5rem;
    }
    .thirdSectionOfBlogPageStartHere {
        padding: 0 0.5rem 4rem 0.5rem;
    }
}
@media only screen and (max-width: 374px) {
    .firstTwoAlsoReadCard {
        justify-content: center;
        align-items: center;
    }
    .alsoReadBlogsImageAndParaDiv {
        flex-direction: column;
    }
}