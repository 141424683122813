
.our-creators-section-start-here {
    padding: 3rem 0 3rem 0;
}

.our-creators-heading-div {
    padding: 0rem 0 1rem 0;
}

.our-creators-heading-div p {
    color: #c08b00;
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    margin-bottom: 0rem;
}

.our-creators-heading-div h6 {
    color: #000;
    font-size: 33px;
    font-weight: 700;
    line-height: 71px;
    margin-bottom: 0px;
    margin-top: -15px;
    text-align: center;
}

.our-creators-image-and-para-div {
    margin: 2rem 0;
}

.our-creators-image-and-para-first-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.our-creators-heading-and-para-div {
    padding-top: 0rem;
    margin-top: -1rem;
}

.our-creators-heading-and-para-div h3, .our-creators-heading-and-para-div p {
    text-align: center;
    color: #000000;
}

.our-creators-heading-and-para-div h3 {
    font-weight: 700;
}

.our-creators-heading-and-para-div p {
    font-weight: 500;
    margin-bottom: 0rem;
}

.our-creators-image-div img {
    width: 225px;
    height: auto;
    border-radius: 50%;
}
.our-creators-icon-div img {
    transform: translate(100%, -64%);
    width: 41px;
    cursor: pointer;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 425px) {
    .our-creators-icon-div img {
        width: 31px;
    }
    .our-creators-image-div img {
        width: 151px;
    }
    .our-creators-heading-and-para-div h3 {
        font-size: 19px;
    }
    .our-creators-heading-and-para-div p {
        font-size: 15px;
    }
}