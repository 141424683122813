
.faq-on-about-us-page-section-start-here {
    padding: 3rem 0;
}

.faq-comp-heading-and-para-div h2, .faq-comp-heading-and-para-div p {
    text-align: center;
}

.faq-comp-heading-and-para-div h2 {
    color: #000000;
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 47px;
}

.faq-comp-heading-and-para-div p {
    color: #667085;
    font-size: 21px;
    line-height: 35px;
}

.faq-comp-main-div {
    margin: 1rem 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem 0 0.5rem 1rem;
    border-bottom: 1px solid #EAECF0;
    cursor: pointer;
}

.faq-question h4 {
    font-weight: 600;
    color:#101828;
    line-height: 39px;
    font-size: 20px;
    text-align: justify;
}

.faq-answer {
    color:#8A8A8A;
    font-weight: 500;
    line-height: 35px;
    font-size: 17px;
    padding-left: 1rem;
    padding-top: 1rem;
    overflow: hidden;
    height: 0;
    /* display: none; */
    transition: all 0.5s cubic-bezier(0,1,0,1);
    text-align: justify;
}

.show {
    /* display: block; */
    height: 100%;
    transition: all .5s cubic-bezier(1,0,1,0);
}

@media only screen and (max-width: 767px) {
    .faq-on-about-us-page-section-start-here {
        padding: 3rem 1rem;
    }
}