
/* Banner Section */
.banner-section-content-and-form-section-start-here {
    padding: 3rem 2rem;
    background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
}

.col-lg-7.banner-section-heading-and-para-div {
    padding-right: 3rem;
}

.banner-section-heading-and-para-div {
    margin-bottom: 2rem;
}

.banner-section-heading-and-para-div h1 {
    font-weight: 700;
    font-size: 41px;
    line-height: 59px;
    color: #000000;
    text-align: justify;
}

.banner-section-heading-and-para-div p, .last-heading-para-and-button-div p {
    font-weight: 500;
    font-size: 22px;
    line-height: 39px;
    color: #000000;
    text-align: justify;
}

.banner-section-heading-and-para-div button, .call-to-action-button-div button, .last-heading-para-and-button-div button {
    padding: 0.75rem 2.5rem;
    background: #000000;
    border-radius: 72px;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    border: none;
    outline: none;
}

/* Social Statistics Section */
.social-statistics-section-start-here {
    padding: 3rem 0;
}

.social-statistics-card {
    margin: 1rem 0;
}

.social-statistics-card h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 33px;
    color: #000000;
}

.social-statistics-card p, .first-content-div p, .call-to-action-content-icon-div p, .second-content-div p, .our-services-heading-and-content-div p, .industry-name-div p, .why-choose-us-image-heading-and-para-first-inner-div p {
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #000000;
}

/* Work Samples */
.work-samples-section-start-here {
    padding-bottom: 3rem;
}

/* First Heading, Image and Content Section */
.first-image-and-content-section-start-here {
    padding: 1rem 2rem 2rem 2rem;
}

.first-image-and-content-heading-div, .second-image-and-content-heading-div, .our-services-heading-div, .industries-we-serve-div, .why-choose-us-heading-div, .benfits-of-agency-image-and-content-heading-div {
    padding-bottom: 1rem;
}

.first-image-and-content-heading-div p, .second-image-and-content-heading-div p, .our-services-heading-div p, .industries-we-serve-div p, .why-choose-us-heading-div p, .benfits-of-agency-image-and-content-heading-div p {
  font-weight: 700;
  font-size: 21px;
  line-height: 41px;
  color: #c08b00;
  text-align: center;
}

.first-image-and-content-heading-div h6, .second-image-and-content-heading-div h6, .our-services-heading-div h6, .industries-we-serve-div h6, .why-choose-us-heading-div h6, .benfits-of-agency-image-and-content-heading-div h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 71px;
  color: #000000;
  margin-top: -30px;
  text-align: center;
}

.first-content-div p, .second-content-div p, .call-to-action-content-icon-div p .our-services-heading-and-content-div p, .why-choose-us-image-heading-and-para-first-inner-div p {
    text-align: justify;
    font-size: 19px;
}

/* CALL TO ACTION Button Div */
.content-email-phone-and-call-to-action-button-section-start-here {
    padding: 0.5rem 2rem 3rem 2rem;
}

.container.content-email-phone-and-call-to-action-button-first-inner-div {
    background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
    padding: 2rem 3rem;
    border-radius: 22px;
}

.call-to-action-content-icon-div .png-icon {
    margin-right: 1rem;
}

.call-to-action-content-icon-div p {
    text-align: justify;
}

.call-to-action-content-icon-div span img {
    width: 37px;
    height: auto;
}

.call-to-action-button-div a {
    text-decoration: none;
}

.call-to-action-button-div button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    top: 41%;
}

/* Our Services Section */
.our-services-section-start-here {
    padding: 1rem 2rem 2rem 2rem;
}

.our-services-image-div, .our-services-heading-and-content-div {
    margin: 1rem 0;
} 

.our-services-heading-and-content-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.our-services-heading-and-content-div p {
    text-align: justify;
    font-size: 19px;
}

.our-services-heading-and-content-div h3 {
    font-weight: 600;
    line-height: 39px;
    margin-bottom: 1rem;
}

.our-services-image-div img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* Second Heading, Image and Content Section */
.second-image-and-content-section-start-here {
    padding: 1rem 2rem 2rem 2rem;
}

/* Industries We Cater Section */
.industries-we-serve-section-start-here {
    padding: 0.5rem 2rem 2rem 2rem;
}

.industry-name-div p {
    font-size: 21px;
}

.industry-name-div img {
    width: 29px;
    height: auto;
    margin-right: 0.5rem;
}

.industry-name-div p a {
    text-decoration: none;
    color: #000000;
    font-weight: 600 !important;
}

.industry-name-div p a:hover {
    border-bottom: 2px solid #c08b00;
}

/* Why Choose Us Section */
.why-choose-us-section-start-here {
    padding: 1rem 2rem 2rem 2rem;
}

.why-choose-us-image-heading-and-para-div {
    margin: 2rem 0;
}

.why-choose-us-image-heading-and-para-first-inner-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.25);
    height: 100%;
    padding: 2rem 2rem;
}

.why-choose-us-image-heading-and-para-div img {
    width: 95px;
    height: auto;
    filter: drop-shadow(0px 13px 8px rgba(19, 19, 19, 0.37));
    margin-bottom: 27px;
    transition: all 0.3s ease-in-out;
}

.why-choose-us-image-heading-and-para-div img:hover {
    transform: scale(1.2) translateZ(0px);
}

.why-choose-us-image-heading-and-para-first-inner-div h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 39px;
}

.why-choose-us-image-heading-and-para-first-inner-div p {
    line-height: 35px;
}

/* Benefits of Content Writing Agency Section */
.benfits-of-agency-image-and-content-section-start-here {
    padding: 1rem 2rem 1rem 2rem;
}

.benfits-of-agency-content-div ul {
    list-style: none;
}

.benfits-of-agency-content-div ul li {
    font-size: 19px;
    line-height: 35px;
    color: #000000;
    font-weight: 500;
    margin: 0.5rem 0;
    text-align: justify;
}

.benfits-of-agency-content-div ul li span {
    margin-right: 0.75rem;
    font-size: 18px;
}

/* Our Content Delivery Process Section */
.how-we-work-section-start-here {
    padding: 3rem 1rem 0rem 1rem;
}

/* FAQs Section */
.faq-on-main-landing-page-page-section-start-here {
    padding: 1rem 1rem 2rem 1rem;
}

/* Last Section */
.last-heading-para-and-button-section-start-here {
    padding-bottom: 3rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.last-heading-para-and-button-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.last-heading-para-and-button-div h2 {
    text-align: center;
    line-height: 49px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 1rem;
    font-size: 37px;
}

.last-heading-para-and-button-div h2 span {
    color: #c08b00;
}

.last-heading-para-and-button-div p {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 600;
}

.last-heading-para-and-button-div a {
    text-decoration: none;
    margin-top: 1rem;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 991px) {
    .col-lg-7.banner-section-heading-and-para-div {
        padding-right: 5rem;
        padding-left: 5rem;
    }
    .banner-section-form-div {
        padding: 0 5rem;
    }
    .first-image-div img, .second-image-div img, .benfits-of-agency-image-div img {
        display: flex;
        margin: 0 auto;
    }
    .container.content-email-phone-and-call-to-action-button-first-inner-div {
        padding: 2rem 4rem;
        max-width: 100%;
    }
    .call-to-action-button-div button {
        top: 0%;
    }
    .container.our-services-section-first-inner-div {
        max-width: 100%;
    }
    .second-image-and-content-section-start-here {
        padding: 1rem 2rem 1rem 2rem;
    }
}
@media only screen and (max-width: 767px) {
    .container.social-statistics-section-first-inner-div, .container.last-heading-para-and-button-section-first-inner-div {
        max-width: 100%;
    }
    .social-statistics-section-start-here {
        padding: 3rem 1rem;
    }
    .banner-section-content-and-form-section-start-here {
        padding: 1rem 2rem 2rem 2rem;
        background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
    }
    .col-lg-7.banner-section-heading-and-para-div {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .banner-section-form-div {
        padding: 0 2rem;
    }
}
@media only screen and (max-width: 650px) {
    .first-image-and-content-heading-div h6, .second-image-and-content-heading-div h6, .our-services-heading-div h6, .industries-we-serve-div h6, .why-choose-us-heading-div h6, .benfits-of-agency-image-and-content-heading-div h6 {
        line-height: 54px;
    }
    .first-image-and-content-heading-div p, .second-image-and-content-heading-div p, .our-services-heading-div p, .industries-we-serve-div p, .why-choose-us-heading-div p, .benfits-of-agency-image-and-content-heading-div p {
        line-height: 53px;
    }
}
@media only screen and (max-width: 575px) {
    .social-statistics-section-start-here {
        padding: 3rem 1rem 1rem 1rem;
    }
    .container.content-email-phone-and-call-to-action-button-first-inner-div {
        padding: 2rem 2rem;
    }
    .col-lg-7.banner-section-heading-and-para-div {
        padding-right: 0rem;
        padding-left: 0rem;
    }
    .banner-section-form-div {
        padding: 0 0rem;
    }
    .first-image-and-content-section-start-here, .our-services-section-start-here, .why-choose-us-section-start-here {
        padding: 1rem 0.5rem 2rem 0.5rem;
    }
    .content-email-phone-and-call-to-action-button-section-start-here {
        padding: 0.5rem 0.5rem 3rem 0.5rem;
    }
    .second-image-and-content-section-start-here, .benfits-of-agency-image-and-content-section-start-here {
        padding: 1rem 0.5rem 1rem 0.5rem;
    }
    .industries-we-serve-section-start-here {
        padding: 0.5rem 0.5rem 2rem 0.5rem;
    }
    .benfits-of-agency-content-div ul {
        padding-left: 0rem;
    }
    .last-heading-para-and-button-section-start-here {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}
@media only screen and (max-width: 400px) {
    .call-to-action-content-icon-div span img {
        width: 32px;
    }
    .call-to-action-content-icon-div .png-icon {
        margin-right: 0.5rem;
    }
    .call-to-action-content-icon-div p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 375px) {
    .banner-section-heading-and-para-div p, .last-heading-para-and-button-div p {
        font-size: 20px;
    }
    .banner-section-heading-and-para-div h1 {
        font-size: 37px;
    }
}
@media only screen and (max-width: 360px) {
    .container.content-email-phone-and-call-to-action-button-first-inner-div {
        padding: 2rem 0.5rem;
    }
}