/* CSS of First Section Start Here */
.firstSectionOfBlogsAndArticlesPageStartHere {
  padding: 2.5rem 0 2.5rem 0;
  background: linear-gradient(89.43deg, #FFF7D9 0.58%, #FFE896 109.13%);
}

.blogsAndArticlesHeadingAndParaDiv {
  padding-top: 0.5rem;
}

.blogsAndArticlesHeadingAndParaDiv h1 {
  font-weight: 700;
  font-size: 41px;
  line-height: 59px;
  color: #000000;
  text-align: justify;
}

.blogsAndArticlesHeadingAndParaDiv p {
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  text-align: justify;
  color: #000000;
}

/* Responsive Page's CSS of First Section Start Here */
@media only screen and (max-width: 1200px) {
  .blogsAndArticlesHeadingAndParaDiv {
    padding-top: 0rem;
  }
}
@media only screen and (max-width: 900px) {
  .blogsAndArticlesHeadingAndParaDiv h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .blogsAndArticlesHeadingAndParaDiv h1,
  .blogsAndArticlesHeadingAndParaDiv p {
    text-align: center;
  }
  .firstSectionOfBlogsAndArticlesPageStartHere {
    padding: 2.5rem 2.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .firstSectionOfBlogsAndArticlesPageStartHere {
    padding: 0.5rem 0.5rem;
  }
}

/* CSS of Second Section Start Here */
.secondPageOfBlogsAndArticlesPageStartHere {
  padding-bottom: 2rem;
}

.blogsAndArticlesSecondSectionHeadingAndParaDiv {
  padding-top: 3rem;
}

.blogsAndArticlesSecondSectionHeadingAndParaDiv
  .blogsAndArticlesSecondSectionHeadingPara,
.thirdSectionHeadingDiv p,
.driveTrafficArticlesFourthSectionHeadingDiv p, .first-question-heading-div p, .second-question-heading-div p, .third-question-heading-div p {
  font-weight: 700;
  font-size: 21px;
  line-height: 41px;
  color: #c08b00;
}

.blogsAndArticlesSecondSectionHeadingAndParaDiv h6,
.thirdSectionHeadingDiv h6,
.driveTrafficArticlesFourthSectionHeadingDiv h6, .first-question-heading-div h6, .second-question-heading-div h6, .third-question-heading-div h6 {
  font-weight: 700;
  font-size: 33px;
  line-height: 51px;
  color: #000000;
  margin-top: -30px;
}

/* Questions CSS */
.first-question-section-start {
  padding-bottom: 2rem;
}

.first-question-heading-div h6, .second-question-heading-div h6, .third-question-heading-div h6, .first-question-heading-div p, .second-question-heading-div p, .third-question-heading-div p {
  text-align: center;
}

.blogsAndArticlesSecondSectionHeadingAndParaDiv
  .blogsAndArticlesSecondSectionParaDesc, .first-question-para-div p, .second-question-para-div p, .third-question-para-div p, .first-question-para-div ul li, .second-question-para-div ul li, .third-question-para-div ul li {
  font-weight: 500;
  font-size: 19px;
  line-height: 35px;
  text-align: justify;
  color: #393939;
}

.first-question-para-div ul li, .second-question-para-div ul li, .third-question-para-div ul li {
  margin: 1rem 0;
}

/* Responsive Page's CSS of Second Section Start Here */
@media only screen and (max-width: 1100px) {
  .blogsAndArticlesSecondSectionHeadingAndParaDiv {
    padding-top: 1rem;
  }
}
@media only screen and (max-width: 991px) {
  .container.secondPageOfBlogsAndArticlesPageFirstInnerDiv {
    max-width: 100%;
  }
  .blogsAndArticlesSecondSectionHeadingAndParaDiv h6 {
    line-height: 45px;
    margin-top: -17px;
  }
}
@media only screen and (max-width: 900px) {
  .blogsAndArticlesSecondSectionImgDiv img {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .blogsAndArticlesSecondSectionImgDiv img {
    width: 500px;
    padding-top: 0rem;
    display: flex;
    margin: auto;
  }
  .blogsAndArticlesSecondSectionHeadingAndParaDiv h6,
  .blogsAndArticlesSecondSectionHeadingAndParaDiv
    .blogsAndArticlesSecondSectionHeadingPara {
    text-align: center;
  }
  .secondPageOfBlogsAndArticlesPageStartHere {
    padding: 0 2.5rem 2rem 2.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .secondPageOfBlogsAndArticlesPageStartHere {
    padding: 0 0.8rem 2rem 0.8rem;
}
}

/* CSS of Third Section Start Here */
.thirdSectionHeadingDiv p,
.thirdSectionHeadingDiv h6 {
  text-align: center;
}

.thirdSectionOfBlogsAndArticlesPageStartHere {
  background-image: url("../../../Image/Categories_Page/Select_Services.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 600px;
  width: 100%;
  padding: 5% 0;
}

.ImageHeadingAndParaContentUnderTable table tr td img {
  width: 131px;
}

.ImageHeadingAndParaContentUnderTable table tr td h6 {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.ImageHeadingAndParaContentUnderTable table tr td p {
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  margin-top: 15px;
  text-align: justify;
  color: #393939;
}

/* Responsive Page's CSS of Third Section Start Here */
@media only screen and (max-width: 991px) {
  .container.thirdSectionOfBlogsAndArticlesPageFirstInnerDiv {
    max-width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 185px;
  }
}
@media only screen and (max-width: 800px) {
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 225px;
  }
}
@media only screen and (max-width: 767px) {
  .thirdSectionHeadingDiv h6 {
    line-height: 45px;
    margin-top: -19px;
  }
  .thirdSectionOfBlogsAndArticlesPageStartHere {
    padding: 5% 2.5rem;
  }
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 65px;
  }
}
@media only screen and (max-width: 576px) {
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 6.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 9.5rem;
  }
  .thirdSectionOfBlogsAndArticlesPageStartHere {
    padding: 5% 0.5rem;
  }
}
@media only screen and (max-width: 334px) {
  .ImageHeadingAndParaContentUnderTable table tr td img {
    width: 12.5rem;
}
}

/* CSS of Fourth Section Start Here */
.fourthSectionOfBlogsAndArticlesPageStartHere {
  padding-bottom: 3rem;
}

.fourthSectionOfBlogsAndArticlesPageHeadingDivStartHere {
  padding-bottom: 5%;
}

.driveTrafficArticlesFourthSectionHeadingDiv p,
.driveTrafficArticlesFourthSectionHeadingDiv h6 {
  text-align: center;
}

.driveTrafficArticlesFourthSectionHeadingDiv h6 {
  margin-bottom: 3.5rem;
}

.fourthSectionOfBlogsAndArticlesPageInnerDivStartHere,
.fourthSectionOfBlogsAndArticlesCardDiv {
  background: #fff4cb;
  border-radius: 21px;
}

.fourthSectionOfBlogsAndArticlesCardDiv {
  padding: 1.5rem 2rem;
}

.cardHeadingAndParaInnerDiv {
  position: absolute;
  transform: translate(0rem, -5rem);
  background: #ffd84a;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 2rem 2rem;
  width: 21rem;
}

.fourthSectionOfBlogsAndArticlesCardDiv h6,
.cardHeadingAndParaInnerDiv h6 {
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;
  color: #000000;
  text-align: center;
}

.fourthSectionOfBlogsAndArticlesCardDiv p,
.fourthSectionOfBlogsAndArticlesCardDiv h6 {
  padding: 0 3rem;
}

.fourthSectionOfBlogsAndArticlesCardDiv p,
.cardHeadingAndParaInnerDiv p {
  font-weight: 400;
  font-size: 17px;
  line-height: 31px;
  text-align: justify;
  color: #393939;
}

/* Responsive Page's CSS of Fourth Section Start Here */
@media only screen and (max-width: 1199px) {
  .cardHeadingAndParaInnerDiv {
    transform: translate(-1.2rem, -5rem);
}
.fourthSectionOfBlogsAndArticlesCardDiv p, .fourthSectionOfBlogsAndArticlesCardDiv h6 {
  padding: 0 1rem;
}
}
@media only screen and (max-width: 991px) {
  .container.fourthSectionOfBlogsAndArticlesPageInnerDivStartHere {
    max-width: 100%;
  }
  .fourthSectionOfBlogsAndArticlesPageStartHere {
    padding: 0 2rem;
  }
  .secondPageOfBlogsAndArticlesPageStartHere {
    padding: 2rem 1rem 4rem 1rem;
}
  .cardHeadingAndParaInnerDiv {
    transform: translate(-1.5rem, -4rem);
  }
}
@media only screen and (max-width: 900px) {
  .cardHeadingAndParaInnerDiv {
    transform: translate(-1rem, -4rem);
    width: 18rem;
  }
}
@media only screen and (max-width: 800px) {
  .cardHeadingAndParaInnerDiv {
    transform: translate(-2rem, -4rem);
  }
  .fourthSectionOfBlogsAndArticlesCardDiv p, .fourthSectionOfBlogsAndArticlesCardDiv h6 {
    padding: 0 2rem;
}
}
@media only screen and (max-width: 767px) {
  .driveTrafficArticlesFourthSectionHeadingDiv h6 {
    line-height: 45px;
    margin-top: -19px;
  }
  .cardHeadingAndParaInnerDiv {
    position: relative;
    transform: translate(0rem, -0.5rem);
    width: 100%;
  }
  .fourthSectionOfBlogsAndArticlesPageHeadingDivStartHere {
    padding-bottom: 0%;
  }
}
@media only screen and (max-width: 425px) {
  .fourthSectionOfBlogsAndArticlesPageStartHere {
    padding: 0 0.5rem;
}
}

/* CSS of Seventh Section Start Here */
.seventhSectionOfBlogsAndArticlesPageStartHere {
  background: #ffe589;
  padding: 5% 0;
}

.subscribeFormDivUnderBlogsAndArticlesPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subscribeFormDivUnderBlogsAndArticlesPage h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 71px;
  color: #000000;
  text-align: center;
}

/* Responsive Page's CSS of Seventh Section Start Here */
@media only screen and (max-width: 991px) {
  .seventhSectionOfBlogsAndArticlesPageStartHere {
    background: #ffe589;
    padding: 7% 0;
  }
  .subscribeFormDivUnderBlogsAndArticlesPage h2 {
    font-size: 31px;
    line-height: 49px;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (max-width: 425px) {
  .subscribeFormDivUnderBlogsAndArticlesPage h2 {
    font-size: 27px;
  }
}
