
.page-not-found-image-div img {
    position: relative;
    top: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.page-not-found-button-div a {
    text-decoration: none;
}

.page-not-found-button-div button {
    position: relative;
    top: -4rem;
    /* left: 43%; */
    display: flex;
    margin: 0 auto;
    background: #FFC727;
    outline: none;
    border: none;
    font-weight: 400;
    padding: 0.5rem 1.3rem;
    font-size: 21px;
}

.page-not-found-button-div button span {
    margin-right: 0.5rem;
}