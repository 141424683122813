.individualBlogPagesHeadingAndSocialMediaIcon {
    padding-top: 2rem;
}

.headingOfTheBlog h1 {
    font-weight: 700;
    font-size: 33px;
    line-height: 57px;
    color: #000000;
}

.headingOfTheBlog p {
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #555555;
}

.socialMediaIconOnTop ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    transform: translateY(1.2rem);
}

.socialMediaIconOnTop ul li i {
    color: #555555;
    font-size: 27px;
    cursor: pointer;
}

.socialMediaIconOnTop ul li a {
    color: #555555;
    font-size: 27px;
    cursor: pointer;
}

/* Copied Link Div */
.showCopiedContent {
    /* position: absolute; */
    /* right: 4rem; */
    background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
    padding: 0.7rem;
    border-radius: 5px;
    width: 375px;
    /* transition: all 0.5s ease-in-out; */
}

.showCopiedContent p {
    margin-bottom: 0rem;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 991px) {
    .individualBlogPagesHeadingAndSocialMediaIconSecondInnerDiv {
        align-items: center;
        flex-direction: column;
    }
    .col-md-8.headingOfTheBlog {
        width: 100%;
    }
    .headingOfTheBlog {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .headingOfTheBlog h1, .headingOfTheBlog p {
        text-align: center;
    }
    .headingOfTheBlog p {
        margin-bottom: 0rem;
    }
    .socialMediaIconOnTop {
        width: 100%;
    }
    .socialMediaIconOnTop ul {
        justify-content: center;
        padding-bottom: 1.5rem;
    }
    .socialMediaIconOnTop ul li {
        margin: 0 2rem;
    }
}
@media only screen and (max-width: 500px) {
    .headingOfTheBlog h1 {
        line-height: 49px;;
    }
    .socialMediaIconOnTop ul li {
        margin: 0 1.5rem;
    }
}
@media only screen and (max-width: 375px) {
    .socialMediaIconOnTop ul {
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 355px) {
    .socialMediaIconOnTop ul li {
        margin: 0 1rem;
    }
}