.footerSectionStartHere {
  padding: 4rem 0 1rem 0;
}

.footerSectionStartHere {
  background-image: url("../../Image/Footer/Footer_Background.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.col-md-4.footerLogoAndSocialMediaIcon {
  padding-left: 3rem;
  position: relative;
  top: -1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.footerLogoAndSocialMediaIcon .footerLogoImg {
  width: 141px;
  cursor: pointer;
  margin-left: 4rem;
}

.footerLogoAndSocialMediaIcon ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  left: 0px;
  top: 41px;
  padding-left: 0rem;
}

.footerLogoAndSocialMediaIcon ul li {
  margin: 0px 20px;
}

.footerLogoAndSocialMediaIcon ul li a {
    font-size: 26px;
}

.footerLogoAndSocialMediaIcon ul a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.footerSectionCompanysServices h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 15px;
}

.footerSectionCompanysServices p a {
  text-decoration: none;
  font-weight: 500;
  font-size: 17px;
  line-height: 33px;
  color: #b1b1b1;
  font-family: var(--font-family-5);
}

.footerSectionCompanysServices p a:hover {
  border-bottom: 2px solid #ffffff;
}

.footerSectionCompanysServices {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.services-company,
.industries-legal {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.services-company {
  align-items: center;
}

.industries-legal {
  align-items: flex-end;
}

.footerSectionBottomContent {
  padding-bottom: 1rem;
}

.sign-up-for-newsletter-div, .privacy-and-terms-of-service {
  margin-top: 5.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0 3rem;
}

.privacy-and-terms-of-service {
  align-items: center;
  margin-top: 6rem;
  justify-content: flex-start;
}

.sign-up-for-newsletter-div input {
  font-weight: 700;
  font-size: 15px;
  line-height: 33px;
  padding: 0.1rem 0.5rem;
  color: #000000;
  border-radius: 9px;
  outline: none;
  font-family: var(--font-family-1);
  border: 2px solid #ffffff;
  background: transparent;
}

.sign-up-for-newsletter-div button {
  background: transparent;
  border-radius: 9px;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding: 0.3rem 2rem;
  font-family: var(--font-family-4);
  border: 2px solid #ffffff;
  outline: none;
  margin-left: 2rem;
}

.footerSectionBottomContent p {
  color: #b1b1b1;
  margin-bottom: 0;
}

.footerSectionBottomContent .footer-all-rights {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  color: #b1b1b1;
  margin-top: 3.5rem;
  font-family: var(--font-family-1);
}

.footerSectionBottomContent .legal-services {
  font-size: 18px;
}

.privacy-and-terms-of-service .legal-services {
  text-align: center;
  font-weight: 700;
}

.privacy-and-terms-of-service .legal-services a {
  color: #b1b1b1;
  text-decoration: none;
  margin: 0 0.5rem;
}

.privacy-and-terms-of-service .legal-services a:hover {
  border-bottom: 2px solid #ffffff;
}

.second-part-of-footer-section-start-here {
  padding: 0.6rem 3rem;
  background: linear-gradient(89.43deg, #fff7d9 0.58%, #ffe896 109.13%);
}

.second-footer-inner-div p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
}

.second-footer-inner-div .legal-services {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.second-footer-inner-div .legal-services a {
  color: #000000;
  text-decoration: none;
  margin: 0 0.5rem;
}

.second-footer-inner-div .legal-services a:hover {
  border-bottom: 2px solid #000000;
}

/* Responsive Footer Section Start Here */
@media only screen and (max-width: 1083px) {
  .sign-up-for-newsletter-div button {
    margin-left: 1rem;
}
}
@media only screen and (max-width: 1051px) {
  .sign-up-for-newsletter-div button {
    margin-left: 0.5rem;
}
.sign-up-for-newsletter-div, .privacy-and-terms-of-service {
  padding: 0 2rem;
}
}
@media only screen and (max-width: 991px) {
  .sign-up-for-newsletter-div {
    margin-top: 0.7rem;
  }
  .footerLogoAndSocialMediaIcon .footerLogoImg {
    margin-left: 0rem;
  }
  .privacy-and-terms-of-service, .sign-up-for-newsletter-div {
    justify-content: center;
}
.privacy-and-terms-of-service {
  margin-top: 2rem;
}
  .col-md-8.footerSectionCompanysServices {
    padding-right: 0rem;
  }
  .footerSectionBottomContent .footer-all-rights {
    margin-top: 0.5rem;
  }
  .col-md-4.footerLogoAndSocialMediaIcon {
    top: 0;
    align-items: center;
  }
  .footerSectionSecondInnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .col-md-8.footerSectionCompanysServices {
    width: -webkit-fill-available;
    margin-top: 65px;
  }
  .footerLogoAndSocialMediaIcon ul {
    top: 45px;
  }
  .footerLogoAndSocialMediaIcon ul li a {
    margin: 0 45px;
  }
}
@media only screen and (max-width: 972px) {
  .sign-up-for-newsletter-div, .privacy-and-terms-of-service {
    padding: 0 0rem;
}
}
@media only screen and (max-width: 892px) {
  .col-md-6.footerSectionBottomContent {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .col-md-4.footerLogoAndSocialMediaIcon {
    padding-left: 2rem;
  }
  .col-md-8.footerSectionCompanysServices {
    padding-right: 0rem;
  }
  .second-footer-inner-div .legal-services {
    justify-content: center;
}
.second-footer-inner-div p {
  text-align: center;
}
.second-footer-inner-div .footer-all-rights {
  margin-bottom: 0.5rem;
}
  .footerLogoAndSocialMediaIcon .footerLogoImg {
    margin: 0 auto;
    display: flex;
  }
  .footerLogoAndSocialMediaIcon ul {
    justify-content: center;
  }
  .footerLogoAndSocialMediaIcon ul li {
    margin: 0px 0px;
  }
  .footerSectionCompanysServices {
    flex-direction: column;
  }
  .industries-legal,
  .services-company {
    justify-content: space-around;
    width: 100%;
  }
  .company {
    position: relative;
    left: -3.5rem;
}
  .second-footer-inner-div p {
    font-size: 15px;
}
}
@media only screen and (max-width: 600px) {
  .footerLogoAndSocialMediaIcon ul {
    top: 34px;
  }
  .footerLogoAndSocialMediaIcon ul li {
    margin: 0px 0px;
  }
}
@media only screen and (max-width: 425px) {
  .col-md-8.footerSectionCompanysServices {
    padding-left: 4rem;
  }
  .company {
    left: 0;
}
.sign-up-for-newsletter-div form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sign-up-for-newsletter-div input {
  margin-bottom: 0.7rem;
}
  .services-company,
  .industries-legal {
    display: block;
  }
  .sign-up-for-newsletter-div {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  .footerSectionBottomContent p {
    font-size: 16px;
    margin-top: 0rem;
    position: relative;
    left: 0px;
  }
  .sign-up-for-newsletter-div {
    margin-top: 0.5rem;
  }
  .footerLogoAndSocialMediaIcon ul img {
    width: 25px;
  }
  .footerLogoAndSocialMediaIcon ul li a {
    margin: 0 25px;
  }
}
@media only screen and (max-width: 376px) {
  .second-footer-inner-div {
    padding: 0;
}
  .second-footer-inner-div .legal-services a {
    margin: 0 0.2rem;
}
.second-footer-inner-div p {
  font-size: 14px;
}
}
@media only screen and (max-width: 375px) {
  .footerLogoAndSocialMediaIcon ul {
    left: 0rem;
  }
  .footerLogoAndSocialMediaIcon ul li {
    margin: 0px 0px;
  }
  .footerLogoAndSocialMediaIcon ul li a {
    margin: 0px 20px;
  }
}
@media only screen and (max-width: 320px) {
  .col-md-8.footerSectionCompanysServices {
    padding-left: 3rem;
  }
}
