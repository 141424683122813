@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Sen', sans-serif;
}

html, body {
  width: 100%;
  /* overflow-x: hidden; */
}

:root {
  --font-family-1: 'Poppins', sans-serif;
  --font-family-2: 'Sen', sans-serif;
  --font-family-3: 'Readex Pro', sans-serif;
  --font-family-4: 'Inter', sans-serif;
  --font-family-5: 'Quicksand', sans-serif;

}

/* Poppins => font-family: 'Poppins', sans-serif; */
/* Sen => font-family: 'Sen', sans-serif; */
/* Readex Pro => font-family: 'Readex Pro', sans-serif; */
/* Inter => font-family: 'Inter', sans-serif; */
/* Quicksand => font-family: 'Quicksand', sans-serif; */

