.subscribeFormDiv input {
    padding: 0.7rem 1rem;
    width: 21rem;
    border: none;
    border-radius: 7px 0 0 7px;
    font-size: 18px;
    outline: none;
}

.subscribeFormDiv button {
    padding: 0.7rem 4rem;
    border: none;
    background-color: #000000;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 0 7px 7px 0;
    font-size: 18px;
    border: none;
    outline: none;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 767px) {
    .subscribeFormDiv input {
        width: 17rem;
    }
}
@media only screen and (max-width: 575px) {
    .subscribeFormDiv input {
        width: 15rem;
    }
    .subscribeFormDiv button {
        padding: 0.7rem 2rem;
    }
}
@media only screen and (max-width: 425px) {
    .subscribeFormDiv input {
        width: 12rem;
    }
}
@media only screen and (max-width: 322px) {
    .subscribeFormDiv input {
        width: 10rem;
    }
}
