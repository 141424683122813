
/* Popular Blogs CSS Start Here */
.popularBlogSectionStartHere {
    margin-bottom: 3rem;
}

.popularBlogsHeading h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.025em;
    color: #000000;
    font-family: var(--font-family-1);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.popularBlogsCardHeadingAndParaDiv {
    padding: 1rem 0;
}

.popularBlogsCardHeadingAndParaDiv h6 {
    font-weight: 500;
    font-size: 19px;
    line-height: 39px;
    font-weight: 600;
    font-family: var(--font-family-1);
}

.popularBlogsCardHeadingAndParaDiv h6 a {
    color: #000000;
    text-decoration: none;
}

.popularBlogsCardHeadingAndParaDiv h6 a:hover {
    border-bottom: 2px solid #000000;
}

.popularBlogsCardHeadingAndParaDiv .popularBlogsPara {
    font-weight: 400;
    font-size: 17px;
    line-height: 31px;
    text-align: justify;
    color: #7E7E7E;
}

.popularBlogsCardHeadingAndParaDiv .readMoreBtn {
    font-weight: 700;
    font-size: 21px;
    line-height: 43px;
}

.popularBlogsCardHeadingAndParaDiv .readMoreBtn a {
    text-decoration: none;
    color: #000000;
}

.popularBlogsCardHeadingAndParaDiv .readMoreBtn a:hover {
    border-bottom: 2px solid #000000;
}

/* Responsive Page of Popular Blogs Start Here */
@media only screen and (max-width: 767px) {
    .popularBlogsHeading h2 {
        text-align: center;
    }
    .popularBlogsCardFirstInnerDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .popularBlogsCardFirstInnerDiv img {
        width: 351px;
        height: 301px;
        object-fit: cover;
        object-position: center center;
    }
    .popularBlogsCardHeadingAndParaDiv {
        padding: 1rem 5rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }
}
@media only screen and (max-width: 575px) {
    .headingOfTheBlog h1 {
        text-align: center;
    }
    .popularBlogsCardMainDiv {
        padding: 0 4rem;
    }
    .popularBlogsCardFirstInnerDiv img {
        width: -webkit-fill-available;
        height: auto;
    }
    .popularBlogsCardFirstInnerDiv {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .popularBlogsCardHeadingAndParaDiv {
        padding: 1rem 0rem;
    }
}